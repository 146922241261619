import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import ImageUploader from "../../misc/ImageUploader";
import ImageZoom from "../../misc/ImageWithZoom";
import Carousel from "../../misc/Carousel";
import Save from "../../../../../icons/save";
import axios from "axios";
import AWS from "aws-sdk";

const CarouselModal = () => {
  const s3 = new AWS.S3({
    accessKeyId: `${process.env.REACT_APP_AWS_KEYID}`,
    secretAccessKey: `${process.env.REACT_APP_AWS_ACCESSKEY}`,
  });
  s3.config.update({ signatureVersion: "v4" });

  const [pictureOneCarouselModal, setPictureOneCarouselModal] =
    useState<File>();
  const [pictureTwoCarouselModal, setPictureTwoCarouselModal] =
    useState<File>();
  const [pictureThreeCarouselModal, setPictureThreeCarouselModal] =
    useState<File>();
  const [pictureFourCarouselModal, setPictureFourCarouselModal] =
    useState<File>();
  const [pictureFiveCarouselModal, setPictureFiveCarouselModal] =
    useState<File>();
  const [pictureOneCarouselModalPreview, setPictureOneCarouselModalPreview] =
    useState<string>("");
  const [pictureTwoCarouselModalPreview, setPictureTwoCarouselModalPreview] =
    useState<string>("");
  const [
    pictureThreeCarouselModalPreview,
    setPictureThreeCarouselModalPreview,
  ] = useState<string>("");
  const [pictureFourCarouselModalPreview, setPictureFourCarouselModalPreview] =
    useState<string>("");
  const [pictureFiveCarouselModalPreview, setPictureFiveCarouselModalPreview] =
    useState<string>("");

  const [pictureOneCarouselModalURL, setPictureOneCarouselModalURL] =
    useState<string>("");
  const [pictureTwoCarouselModalURL, setPictureTwoCarouselModalURL] =
    useState<string>("");
  const [pictureThreeCarouselModalURL, setPictureThreeCarouselModalURL] =
    useState<string>("");
  const [pictureFourCarouselModalURL, setPictureFourCarouselModalURL] =
    useState<string>("");
  const [pictureFiveCarouselModalURL, setPictureFiveCarouselModalURL] =
    useState<string>("");

  const [carouselModalImages, setCarouselModalImages] = useState<any>([]);

  const handleCarouselModal = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    Swal.fire({
      title: "Uploading...",
      text: "Please wait while we upload the images.",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      if (pictureOneCarouselModal) {
        const uploadParams = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `publicidad/${pictureOneCarouselModal?.name}`,
          Body: pictureOneCarouselModal,
        };
        await s3.upload(uploadParams).promise();
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/publicidad?type=modalPictureOne`,
          {
            image: `publicidad/${pictureOneCarouselModal?.name}`,
            external_url: pictureOneCarouselModalURL,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "auth-token": `${sessionStorage.getItem("token")}`,
            },
          }
        );
      }

      if (pictureTwoCarouselModal) {
        const uploadParams = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `publicidad/${pictureTwoCarouselModal?.name}`,
          Body: pictureTwoCarouselModal,
        };
        await s3.upload(uploadParams).promise();
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/publicidad?type=modalPictureTwo`,
          {
            image: `publicidad/${pictureTwoCarouselModal?.name}`,
            external_url: pictureTwoCarouselModalURL,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "auth-token": `${sessionStorage.getItem("token")}`,
            },
          }
        );
      }

      if (pictureThreeCarouselModal) {
        const uploadParams = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `publicidad/${pictureThreeCarouselModal?.name}`,
          Body: pictureThreeCarouselModal,
        };
        await s3.upload(uploadParams).promise();
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/publicidad?type=modalPictureThree`,
          {
            image: `publicidad/${pictureThreeCarouselModal?.name}`,
            external_url: pictureThreeCarouselModalURL,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "auth-token": `${sessionStorage.getItem("token")}`,
            },
          }
        );
      }

      if (pictureFourCarouselModal) {
        const uploadParams = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `publicidad/${pictureFourCarouselModal?.name}`,
          Body: pictureFourCarouselModal,
        };
        await s3.upload(uploadParams).promise();
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/publicidad?type=modalPictureFour`,
          {
            image: `publicidad/${pictureFourCarouselModal?.name}`,
            external_url: pictureFourCarouselModalURL,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "auth-token": `${sessionStorage.getItem("token")}`,
            },
          }
        );
      }

      if (pictureFiveCarouselModal) {
        const uploadParams = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `publicidad/${pictureFiveCarouselModal?.name}`,
          Body: pictureFiveCarouselModal,
        };
        await s3.upload(uploadParams).promise();
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/publicidad?type=modalPictureFive`,
          {
            image: `publicidad/${pictureFiveCarouselModal?.name}`,
            external_url: pictureFiveCarouselModalURL,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "auth-token": `${sessionStorage.getItem("token")}`,
            },
          }
        );
      }
      Swal.fire({
        icon: "success",
        title: "Images uploaded successfully",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "An error occurred during the upload.",
      });
      console.error("Error uploading file:", error);
    }
  };

  const getCarouselModal = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/publicidad/modal`
      );

      let tempImageOne = response.data.filter(
        (image: any) => image.placement === "modalPictureOne"
      );
      let tempImageTwo = response.data.filter(
        (image: any) => image.placement === "modalPictureTwo"
      );

      let tempImageThree = response.data.filter(
        (image: any) => image.placement === "modalPictureThree"
      );

      let tempImageFour = response.data.filter(
        (image: any) => image.placement === "modalPictureFour"
      );

      let tempImageFive = response.data.filter(
        (image: any) => image.placement === "modalPictureFive"
      );

      if (tempImageOne) {
        let paramsS3 = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `${tempImageOne[0].link}`,
          Expires: 60 * 60,
        };

        s3.getSignedUrl("getObject", paramsS3, (err, url) => {
          if (err) {
            console.error(err);
          } else {
            addImageToCarouselModal({
              image: url,
              external_url: tempImageOne[0].external_url,
            });
            setPictureOneCarouselModalPreview(url);
            setPictureOneCarouselModalURL(tempImageOne[0].external_url);
          }
        });
      }

      if (tempImageTwo) {
        let paramsS3 = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `${tempImageTwo[0].link}`,
          Expires: 60 * 60,
        };
        s3.getSignedUrl("getObject", paramsS3, (err, url) => {
          if (err) {
            console.error(err);
          } else {
            addImageToCarouselModal({
              image: url,
              external_url: tempImageTwo[0].external_url,
            });
            setPictureTwoCarouselModalPreview(url);
            setPictureTwoCarouselModalURL(tempImageTwo[0].external_url);
          }
        });
      }

      if (tempImageThree) {
        let paramsS3 = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `${tempImageThree[0].link}`,
          Expires: 60 * 60,
        };
        s3.getSignedUrl("getObject", paramsS3, (err, url) => {
          if (err) {
            console.error(err);
          } else {
            addImageToCarouselModal({
              image: url,
              external_url: tempImageThree[0].external_url,
            });
            setPictureThreeCarouselModalPreview(url);
            setPictureThreeCarouselModalURL(tempImageThree[0].external_url);
          }
        });
      }

      if (tempImageFour) {
        let paramsS3 = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `${tempImageFour[0].link}`,
          Expires: 60 * 60,
        };
        s3.getSignedUrl("getObject", paramsS3, (err, url) => {
          if (err) {
            console.error(err);
          } else {
            addImageToCarouselModal({
              image: url,
              external_url: tempImageFour[0].external_url,
            });
            setPictureFourCarouselModalPreview(url);
            setPictureFourCarouselModalURL(tempImageFour[0].external_url);
          }
        });
      }

      if (tempImageFive) {
        let paramsS3 = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `${tempImageFive[0].link}`,
          Expires: 60 * 60,
        };
        s3.getSignedUrl("getObject", paramsS3, (err, url) => {
          if (err) {
            console.error(err);
          } else {
            addImageToCarouselModal({
              image: url,
              external_url: tempImageFive[0].external_url,
            });
            setPictureFiveCarouselModalPreview(url);
            setPictureFiveCarouselModalURL(tempImageFive[0].external_url);
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addImageToCarouselModal = (newImageUrl: any) => {
    setCarouselModalImages((prevImages: any) => [...prevImages, newImageUrl]);
  };

  useEffect(() => {
    getCarouselModal();
  }, []);

  return (
    <div className="p-2 border border-slate-200 rounded m-2">
      <p>Moda</p>
      <Carousel images={carouselModalImages} />
      <form action="" className="gap-2 my-4" onSubmit={handleCarouselModal}>
        <div className="flex">
          <h1>Seccion Modal</h1>
          <button>
            <Save size={26} />
          </button>
        </div>
        <div className="flex gap-2">
          <div className="flex flex-col gap-2">
            <p>Foto #1</p>
            <input
              value={pictureOneCarouselModalURL}
              type="text"
              className="main-input"
            />
            <ImageUploader
              setSelectedFile={setPictureOneCarouselModal}
              inputId="CarouselModalPictureOne"
            />
            <p>
              <strong>Previo</strong>
            </p>
            <ImageZoom imageUrl={pictureOneCarouselModalPreview} />
          </div>
          <div className="flex flex-col gap-2">
            <p>Foto #2</p>
            <input
              value={pictureTwoCarouselModalURL}
              type="text"
              className="main-input"
            />
            <ImageUploader
              setSelectedFile={setPictureTwoCarouselModal}
              inputId="CarouselModalPictureTwo"
            />
            <p>
              <strong>Previo</strong>
            </p>
            <ImageZoom imageUrl={pictureTwoCarouselModalPreview} />
          </div>
          <div className="flex flex-col gap-2">
            <p>Foto #3</p>
            <input
              value={pictureThreeCarouselModalURL}
              type="text"
              className="main-input"
            />
            <ImageUploader
              setSelectedFile={setPictureThreeCarouselModal}
              inputId="CarouselModalPictureThree"
            />
            <p>
              <strong>Previo</strong>
            </p>
            <ImageZoom imageUrl={pictureThreeCarouselModalPreview} />
          </div>
          <div className="flex flex-col gap-2">
            <p>Foto #4</p>
            <input
              value={pictureFourCarouselModalURL}
              type="text"
              className="main-input"
            />
            <ImageUploader
              setSelectedFile={setPictureFourCarouselModal}
              inputId="CarouselModalPictureFour"
            />
            <p>
              <strong>Previo</strong>
            </p>
            <ImageZoom imageUrl={pictureFourCarouselModalPreview} />
          </div>
          <div className="flex flex-col gap-2">
            <p>Foto #5</p>
            <input
              value={pictureFiveCarouselModalURL}
              type="text"
              className="main-input"
            />
            <ImageUploader
              setSelectedFile={setPictureFiveCarouselModal}
              inputId="CarouselModalPictureFive"
            />
            <p>
              <strong>Previo</strong>
            </p>
            <ImageZoom imageUrl={pictureFiveCarouselModalPreview} />
          </div>
        </div>
      </form>
    </div>
  );
};

export default CarouselModal;
