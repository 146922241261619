import axios from "axios";
import { useEffect, useState } from "react";
import Status from "../../../../../icons/status";

interface Usuario {
  usuario_id: number;
  nombre: string;
  apellido: string;
  estado: string;
}

interface Props {
  farmaciaViewID: number;
}

const FarmaciaUsuarios = ({ farmaciaViewID }: Props) => {
  const [usuarios, setUsuarios] = useState<Usuario[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const filteredUsuarios = usuarios.filter((usuario) => {
    return (
      usuario.nombre.toLowerCase().includes(searchTerm.toLowerCase()) ||
      usuario.apellido.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const getUsuarios = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/usuarios/farmacia/${farmaciaViewID}`,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );

      setUsuarios(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUsuarios();
  }, []);

  return (
    <div>
      <input
        type="text"
        placeholder="Buscar"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="main-input my-4"
      />
      <div
        className="overflow-auto bg-white pb-8 md:pb-0"
        style={{ maxHeight: "calc(40vh)" }}
      >
        <table className="w-full">
          <thead className="bg-gray-50 top-0 sticky">
            <tr className="table-head text-left">
              <th>ID</th>
              <th>Nombre</th>
              <th className="text-center">Estado</th>
            </tr>
          </thead>

          <tbody className="bg-white divide-y divide-[#FAFAFA]">
            {usuarios.length === 0 ? (
              <tr className="hover:bg-slate-300">
                <td className="text-center" colSpan={6}>
                  No hay usuarios
                </td>
              </tr>
            ) : (
              filteredUsuarios.map((usuario) => (
                <tr
                  className="hover:bg-slate-100 table-body "
                  key={usuario.usuario_id}
                >
                  <td>{usuario.usuario_id}</td>
                  <td>
                    {usuario.nombre} {usuario.apellido}
                  </td>
                  <td>
                    <div className="m-auto w-fit">
                      <Status status={usuario.estado} />
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FarmaciaUsuarios;
