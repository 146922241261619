import { useEffect, useState } from "react";
import Menu from "./Menu";
import Premios from "./premios/List";
import Home from "./home/Home";
import Retos from "./retos/List";
import Pedidos from "./pedidos/List";
// import VentaComprobante from "./venta_comprobantes/List";
// import RetosComprobante from "./reto_comprobantes/List";
import Productos from "./productos/List";
import Marca from "./marca/Marca";
import Perfil from "./perfil/Profile";
import Ayuda from "./ayuda/ayuda";
import { useNavigate } from "react-router-dom";
import { validateToken } from "../../../../auth/validateToken";

function StatusContent({ status }: any) {
  switch (status) {
    case "Home":
      return <Home />;
    case "Premios":
      return <Premios />;
    case "Retos":
      return <Retos />;
    case "Pedidos":
      return <Pedidos />;
    case "Marca":
      return <Marca />;
    // case "VentaComprobantes":
    //   return <VentaComprobante />;
    // case "RetoComprobantes":
    //   return <RetosComprobante />;
    case "Perfil":
      return <Perfil />;
    case "Ayuda":
      return <Ayuda />;
    case "Productos":
      return <Productos />;
    default:
      return <div>Unknown status.</div>;
  }
}
const UsuarioHome = () => {
  const [view, setView] = useState("Home");

  const navigate = useNavigate();

  useEffect(() => {
    if (sessionStorage.getItem("rol") === "Usuario") {
      const token = sessionStorage.getItem("token");
      if (token) {
        validateToken(token).then((isValid) => {
          if (!isValid) {
            sessionStorage.clear();
            navigate("/");
          }
        });
      } else {
        navigate("/");
      }
    } else {
      navigate("/");
    }
  }, [navigate]);

  return (
    <div className="flex flex-col h-screen w-screen overflow-hidden">
      <div className="flex flex-col md:flex-row grow overflow-hidden">
        <div className="flex-none w-full md:w-1/6 overflow-auto">
          <Menu view={view} setView={setView} />
        </div>
        <div className="w-full p-4">
          <StatusContent status={view} />
        </div>
      </div>
    </div>
  );
};

export default UsuarioHome;
