import AWS from "aws-sdk";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ImageUploader from "../../misc/ImageUploader";
import Close from "../../../../../icons/close";
import Swal from "sweetalert2";

interface Props {
  view: boolean;
  setView: React.Dispatch<React.SetStateAction<boolean>>;
  counter: any;
  setCounter: React.Dispatch<React.SetStateAction<number>>;
}

interface Premio {
  nombre?: string;
  descripcion?: string;
  puntos?: number;
}

const New = ({ view, setView, setCounter, counter }: Props) => {
  const [selectedFile, setSelectedFile] = useState<File>();
  const [isClosing, setIsClosing] = useState(false);
  const [premio, setPremio] = useState<Premio>({} as Premio);

  const s3 = new AWS.S3({
    accessKeyId: `${process.env.REACT_APP_AWS_KEYID}`,
    secretAccessKey: `${process.env.REACT_APP_AWS_ACCESSKEY}`,
  });
  s3.config.update({ signatureVersion: "v4" });

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setPremio({ ...premio, [name]: value });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      Swal.fire({
        title: "Procesando...",
        text: "No cierre esta ventana.",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      let data;
      if (!premio.nombre || !premio.puntos || !selectedFile) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Por favor llene todos los campos",
        });
        return;
      }
      if (selectedFile) {
        data = {
          nombre: premio.nombre,
          descripcion: premio.descripcion,
          puntos: premio.puntos,
          imagen: `premios/${selectedFile?.name}`,
        };
      } else {
        data = {
          nombre: premio.nombre,
          descripcion: premio.descripcion,
          puntos: premio.puntos,
          imagen: "",
        };
      }

      if (selectedFile) {
        const uploadParams = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `premios/${selectedFile?.name}`,
          Body: selectedFile,
        };
        await s3.upload(uploadParams).promise();
        await axios.post(`${process.env.REACT_APP_API_URL}/api/premios`, data, {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        });
      } else {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/premios`, data, {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        });
      }
      Swal.fire({
        icon: "success",
        title: "Premio creado",
        showConfirmButton: false,
        timer: 1500,
      });
      setCounter(counter + 1);
      setPremio({
        nombre: "",
        descripcion: "",
        puntos: 0,
      });
      setView(!view);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Ha ocurrido un error",
      });
      console.error("Error uploading file:", error);
    }
  };

  useEffect(() => {}, [premio]);

  return (
    <>
      {view || isClosing ? (
        <div className={`${view ? "bg-drawer" : "hidden"}`}>
          <div className="grow"></div>
          <div
            className={`h-full bg-white p-2 w-screen md:w-1/3 md:min-w-fit min-w-full px-6 ${
              isClosing ? "animate-slide-out" : "animate-slide-in"
            }`}
          >
            <div className="w-full sticky top-0 bg-white flex items-center my-4">
              <p className="text-lg">
                <strong>Nuevo Premio</strong>
              </p>
              <div className="grow" />
              <button
                onClick={() => {
                  if (view) {
                    setIsClosing(true);
                    setTimeout(() => {
                      setView(false);
                      setIsClosing(false);
                    }, 1000);
                  } else {
                    setView(true);
                  }
                }}
              >
                <Close />
              </button>
            </div>
            <form
              action=""
              className="grow flex flex-col gap-2"
              onSubmit={handleSubmit}
            >
              <div className="block">
                <label htmlFor="" className="">
                  Nombre*
                </label>
                <input
                  type="text"
                  id="nombre"
                  name="nombre"
                  value={premio.nombre}
                  onChange={handleInputChange}
                  className="main-input"
                  required
                />
              </div>
              <div className="block">
                <label className="">Descripcion</label>
                <textarea
                  id="descripcion"
                  name="descripcion"
                  className="main-input h-24"
                  value={premio.descripcion}
                  onChange={handleInputChange}
                />
              </div>
              <div className="block">
                <label htmlFor="" className="">
                  Puntos*
                </label>
                <input
                  type="number"
                  id="puntos"
                  name="puntos"
                  value={premio.puntos}
                  onChange={handleInputChange}
                  className="main-input"
                  required
                />
              </div>
              <p>Foto*</p>
              <ImageUploader
                setSelectedFile={setSelectedFile}
                inputId="PremioNew"
              />
              <button className={`cefa-button`}>Crear Premio</button>
            </form>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default New;
