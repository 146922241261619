import AWS from "aws-sdk";
import axios from "axios";
import { useEffect, useState } from "react";
import Close from "../../../../../icons/close";
import Cefamonedas from "../../../../../icons/cefamoneda";
import ImageZoomModal from "../../misc/ImageWithZoom";
import Swal from "sweetalert2";

interface Premio {
  premio_id: number;
  nombre: string;
  puntos?: number;
  imagen?: string;
  descripcion?: string;
  estado: string;
}

const s3 = new AWS.S3({
  accessKeyId: `${process.env.REACT_APP_AWS_KEYID}`,
  secretAccessKey: `${process.env.REACT_APP_AWS_ACCESSKEY}`,
});
s3.config.update({ signatureVersion: "v4" });

interface Props {
  premioView: boolean;
  setPremioView: React.Dispatch<React.SetStateAction<boolean>>;
  premioViewID: number;
}

const PremioView = ({ premioView, setPremioView, premioViewID }: Props) => {
  const [premio, setPremio] = useState<Premio>();
  const [url, setUrl] = useState<string>();
  const [isClosing, setIsClosing] = useState(false);

  const getPremio = async (premioViewID: any) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/premios/${premioViewID}`,
        {
          headers: {
            "auth-token": `${sessionStorage?.getItem("token")}`,
          },
        }
      );
      setPremio(response.data);
      const paramsS3 = {
        Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
        Key: `${response.data.imagen}`,
        Expires: 60 * 60,
      };
      s3.getSignedUrl("getObject", paramsS3, (err, url) => {
        if (err) {
          console.error(err);
        } else {
          setUrl(url);
          return;
        }
      });
    } catch (error) {}
  };

  const addPremioCart = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/carrito/${sessionStorage?.getItem(
          "user_id"
        )}/${premio?.premio_id}`,
        {},
        {
          headers: {
            "auth-token": `${sessionStorage?.getItem("token")}`,
          },
        }
      );
      Swal.fire({
        icon: "success",
        title: "Premio agregado al carrito",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error al agregar al carrito",
        text: "Por favor intente de nuevo",
      });
    }
  };

  useEffect(() => {
    if (premioView === false) {
      setTimeout(() => {
        setUrl("");
        setPremio({} as Premio);
      }, 500);
    } else {
      getPremio(premioViewID);
    }
  }, [premioView]);

  return (
    <>
      {premioView || isClosing ? (
        <div className={`${premioView ? "bg-drawer" : "hidden"}`}>
          <div className="grow"></div>
          <div
            className={`h-full bg-white p-2 w-screen md:w-1/3 md:min-w-fit min-w-full px-6 ${
              isClosing ? "animate-slide-out" : "animate-slide-in"
            }`}
          >
            <div className="w-full sticky top-0 bg-white">
              <div className="w-full flex items-center mt-4">
                <p className="text-right mr-4 text-lg flex flex-row">
                  <strong>{premio?.nombre}</strong>
                </p>
                <div className="grow"></div>
                <button
                  onClick={() => {
                    if (premioView) {
                      setIsClosing(true);
                      setTimeout(() => {
                        setPremioView(false);
                        setIsClosing(false);
                      }, 1000);
                    } else {
                      setPremioView(true);
                    }
                  }}
                >
                  <Close />
                </button>
              </div>
            </div>
            <div className="grow p-2 flex flex-col">
              <p className="text-center flex">
                <strong>{premio?.puntos?.toLocaleString()}</strong>
                <Cefamonedas />
              </p>
              <div className="m-auto">
                <ImageZoomModal imageUrl={url} />
              </div>
              <div className="flex gap-2">
                <p>
                  <strong>Descripción:</strong>
                </p>
                <p>{premio?.descripcion}</p>
              </div>
              <br />
              <button onClick={addPremioCart} className="cefa-button">
                Agregar al carrito
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default PremioView;
