import { useEffect, useState } from "react";

const ImageZoomModal = ({ imageUrl }: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {}, [imageUrl]);

  const effectiveImageUrl =
    imageUrl ||
    "https://atlassianblog.wpengine.com/wp-content/uploads/2017/12/44-incredible-404-error-pages@3x-1560x760.png";

  return (
    <div>
      <img
        src={effectiveImageUrl}
        alt="Zoomable"
        onClick={() => setIsModalOpen(true)}
        style={{ cursor: "zoom-in", maxWidth: "100%", maxHeight: "400px" }}
      />
      {isModalOpen && (
        <div
          className="w-screen h-screen bg-black bg-opacity-50 fixed top-0 left-0 flex items-center justify-center z-50"
          onClick={() => setIsModalOpen(false)}
        >
          <div
            className="bg-white rounded-lg shadow-lg overflow-auto"
            style={{
              position: "relative",
              maxWidth: "80%",
              maxHeight: "80%",
              width: "auto",
              padding: "20px",
              boxSizing: "border-box",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <img
              className="m-auto"
              src={effectiveImageUrl}
              alt="Zoomed"
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageZoomModal;
