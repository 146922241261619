import axios from "axios";
import { useEffect, useState } from "react";
import Close from "../../../../../icons/close";
import Swal from "sweetalert2";

interface Usuario {
  nombre: string;
  apellido: string;
  email: string;
}

interface farmacia {
  farmacia_id: number;
  nombre: string;
  direccion: string;
  telefono: string;
  email: string;
}
interface Props {
  view: boolean;
  setView: React.Dispatch<React.SetStateAction<boolean>>;
  counter: number;
  setCounter: React.Dispatch<React.SetStateAction<number>>;
}

const New = ({ view, setView, counter, setCounter }: Props) => {
  const [usuario, setUsuario] = useState<Usuario>({} as Usuario);
  const [farmacias, setFarmacias] = useState<farmacia[]>([]);
  const [selectedFarmacia, setSelectedFarmacia] = useState(0);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setUsuario({ ...usuario, [name]: value });
  };

  const getFarmacias = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/farmacias/allFarmacias`,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );
      setFarmacias(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const farmaciaChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const selectedName = event.target.value;
    const selectedFarmaciaTemp = farmacias.find(
      (farmacia) => farmacia.nombre === selectedName
    );
    setSelectedFarmacia(selectedFarmaciaTemp?.farmacia_id || 0);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      const newUser = {
        nombre: usuario.nombre,
        apellido: usuario.apellido,
        email: usuario.email,
        farmacia_id: selectedFarmacia,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/usuarios/`,
        newUser,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );
      if (response.data.message === "Duplicated user") {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "El usuario ya existe",
          confirmButtonText: "Aceptar",
        });
      } else {
        setCounter(counter + 1);
        setView(!view);
        Swal.fire({
          icon: "success",
          title: "Usuario creado",
          text: "Se ha creado el usuario",
          confirmButtonText: "Aceptar",
        });
      }
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ha ocurrido un error al crear el usuario.",
        confirmButtonText: "Aceptar",
      });
    }
  };

  useEffect(() => {
    getFarmacias();
  }, []);

  return (
    <>
      {view ? (
        <div className={`${view ? "bg-drawer" : "hidden"}`}>
          <div className="grow"></div>
          <div
            className={`h-full bg-white w-screen md:w-1/3 md:min-w-fit min-w-full overflow-scroll px-6 ${
              view ? "animate-slide-in" : "animate-slide-out"
            }`}
          >
            <div className="flex items-center py-2">
              <p className="text-lg">
                <strong>Invitar usuario</strong>
              </p>
              <div className="grow"></div>
              <button onClick={() => setView(!view)}>
                <Close />
              </button>
            </div>
            <form
              className="w-full flex flex-col gap-2"
              onSubmit={handleSubmit}
            >
              <div>
                <label>Nombre*</label>
                <input
                  type="text"
                  id="nombre"
                  name="nombre"
                  onChange={handleInputChange}
                  className="main-input"
                  required
                />
              </div>
              <div>
                <label>Apellido</label>
                <input
                  type="text"
                  id="apellido"
                  name="apellido"
                  onChange={handleInputChange}
                  className="main-input"
                />
              </div>
              <div>
                <label>Email*</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  onChange={handleInputChange}
                  className="main-input"
                  required
                />
              </div>
              <div>
                <label>Farmacia*</label>
                <input
                  type="text"
                  id="farmacia_id"
                  name="farmacia_id"
                  onChange={farmaciaChange}
                  list="names"
                  className="main-input"
                  required
                />
                <datalist id="names">
                  {farmacias.map(({ farmacia_id, nombre }) => (
                    <option key={`${farmacia_id}`} value={`${nombre}`} />
                  ))}
                </datalist>
              </div>
              <button className={`cefa-button`}>Invitar Usuario</button>
            </form>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default New;
