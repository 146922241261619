import axios from "axios";
import { useEffect, useState } from "react";
import UserCard from "./UserCard";
import List from "../retos/List";
import VentaComprobanteModal from "./VentaComprobanteModal";

interface user {
  usuario_id: number;
  premios_recibidos: number;
  retos_completado: number;
  puntos: number;
  name: string;
}

const Home = () => {
  const [modalVenta, setModalVenta] = useState(false);
  const [foto, setFoto] = useState("");
  const [usuario, setUsuario] = useState<user>();

  const getUser = async () => {
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_API_URL
        }/api/usuarios/home/${sessionStorage?.getItem("email")}`,
        {
          headers: {
            "auth-token": `${sessionStorage?.getItem("token")}`,
          },
          params: {
            email: `${sessionStorage?.getItem("email")}`,
          },
        }
      );

      setUsuario(response.data);
      setFoto(response.data.foto);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <div className="w-full">
      <UserCard
        foto={foto}
        puntos={usuario?.puntos || 0}
        retos={usuario?.retos_completado || 0}
        name={usuario?.name || ""}
      ></UserCard>
      <div className="grow w-full m-auto ">
        <button className="cefa-button my-2" onClick={() => setModalVenta(true)}>
          Agregar Venta
        </button>
        <List />
      </div>
      <VentaComprobanteModal view={modalVenta} setView={setModalVenta} />
    </div>
  );
};

export default Home;
