import { useEffect, useRef, useState } from "react";
import axios from "axios";
import Close from "../../../../../icons/close";
import Trash from "../../../../../icons/trash";
import Save from "../../../../../icons/save";
import StatusToggle from "../../../../../icons/toggle";
import Swal from "sweetalert2";

interface Usuario {
  usuario_id: number;
  estado: string;
  nombre: string;
  apellido: string;
  email: string;
  telefono: number;
  puntos: number;
  pais: string;
  provincia: string;
  canton: string;
  distrito: string;
  barrio: string;
  direccion: string;
  farmacia: string;
}

interface Farmacia {
  farmacia_id: number;
  nombre: string;
  direccion: string;
  telefono: string;
  email: string;
}

interface Props {
  usuarioView: boolean;
  setUsuarioView: React.Dispatch<React.SetStateAction<boolean>>;
  usuarioViewID: number;
  setCounter: React.Dispatch<React.SetStateAction<number>>;
}

const UsuarioView = ({
  usuarioViewID,
  usuarioView,
  setUsuarioView,
  setCounter,
}: Props) => {
  const [usuario, setUsuario] = useState<Usuario>({} as Usuario);
  const [farmacias, setFarmacias] = useState<Farmacia[]>([]);
  const [selectedFarmacia, setSelectedFarmacia] = useState(0);
  const formRef = useRef<HTMLFormElement>(null);
  const [usuarioCounter, setUsuarioCounter] = useState(0);

  const getUsuario = async (usuario_id: number) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/usuarios/${usuario_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );
      setUsuario(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getFarmacias = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/farmacias/allFarmacias`,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );
      setFarmacias(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFarmaciaChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const selectedName = event.target.value;
    const selectedFarmaciaTemp = farmacias.find(
      (farmacia) => farmacia.nombre === selectedName
    );
    setSelectedFarmacia(selectedFarmaciaTemp?.farmacia_id || 0);
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setUsuario({ ...usuario, [name]: value });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const data = {
        nombre: usuario.nombre,
        apellido: usuario.apellido,
        email: usuario.email,
        telefono: usuario.telefono,
        pais: usuario.pais,
        provincia: usuario.provincia,
        canton: usuario.canton,
        distrito: usuario.distrito,
        barrio: usuario.barrio,
        direccion: usuario.direccion,
        farmacia_id: selectedFarmacia,
      };
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/usuarios/${usuarioViewID}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );
      setCounter((counter) => counter + 1);
      setUsuarioView(!usuarioView);
      Swal.fire({
        icon: "success",
        title: "¡Éxito!",
        text: "Usuario atualizado con éxito.",
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Opps!",
        text: "Ha ocurrido un error",
      });
      console.log(error);
    }
  };

  //Function that handles the delete action
  const deleteUsuario = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/usuarios/${usuario.usuario_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );
      setCounter((counter) => counter + 1);
      setUsuarioView(!usuarioView);
      Swal.fire({
        icon: "success",
        title: "Eliminado",
        text: "Se ha eliminado el usuario",
        confirmButtonText: "Aceptar",
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ha ocurrido un error",
        confirmButtonText: "Aceptar",
      });
    }
  };

  const setStatus = async () => {
    try {
      let tempState = usuario.estado === "Activo" ? "Inactivo" : "Activo";
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/usuarios/state/${usuario.usuario_id}`,
        { estado: tempState },
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );
      setUsuario({ ...usuario, estado: tempState });
      setCounter((counter) => counter + 1);
      setUsuarioView(!usuarioView);
      Swal.fire({
        icon: "success",
        title: "¡Éxito!",
        text: "El usuario ha sido activado con éxito.",
        confirmButtonText: "Aceptar",
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ha ocurrido un error al activar el usuario.",
        confirmButtonText: "Aceptar",
      });
    }
  };

  const handleSubmitButton = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true })
      );
    }
  };

  useEffect(() => {
    if (usuarioView === false) {
      setTimeout(() => {
        setUsuario({} as Usuario);
        setUsuarioCounter(0);
      }, 500);
    } else {
      getUsuario(usuarioViewID);
      getFarmacias();
    }
  }, [usuarioView]);

  return (
    <>
      {usuarioView ? (
        <div className={`${usuarioView ? "bg-drawer " : "hidden"}`}>
          <div className="grow"></div>
          <div className="h-full bg-white px-4 pb-4 overflow-auto w-screen md:w-1/3 md:min-w-fit min-w-full ">
            <div className="p-2 sticky top-0 bg-white">
              <div className="flex items-center mt-4">
                <p className="text-right">
                  <strong>
                    {usuario.nombre} {usuario.apellido}
                  </strong>
                </p>
                <div className="grow"></div>
                <button
                  onClick={() => {
                    setUsuarioView(!usuarioView);
                  }}
                >
                  <Close />
                </button>
              </div>
              <div className="flex my-4">
                <div className="flex gap-2">
                  <button
                    onClick={() => {
                      Swal.fire({
                        title: "¿Estás seguro?",
                        text: "No podrás revertir esta acción",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Sí, eliminar",
                        cancelButtonText: "Cancelar",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          deleteUsuario();
                        }
                      });
                    }}
                    type="button"
                  >
                    <Trash size={24} />
                  </button>
                  <button onClick={handleSubmitButton}>
                    <Save size={36} />
                  </button>
                </div>
                <div className="grow"></div>
                <StatusToggle status={usuario.estado} setStatus={setStatus} />
              </div>
            </div>

            <div className="grow flex flex-col gap-2 px-2 mt-2">
              <div>
                <form
                  ref={formRef}
                  className="flex flex-col gap-2 "
                  onSubmit={handleSubmit}
                >
                  <div>
                    <label>Farmacia</label>
                    <input
                      type="text"
                      id="farmacia_id"
                      name="farmacia_id"
                      defaultValue={usuario.farmacia}
                      onChange={handleFarmaciaChange}
                      list="names"
                      className="main-input"
                    />
                    <datalist id="names">
                      {farmacias.map(({ farmacia_id, nombre }) => (
                        <option key={`${farmacia_id}`} value={`${nombre}`} />
                      ))}
                    </datalist>
                  </div>
                </form>
              </div>
              <div className="flex gap-4">
                <div className="grow">
                  <label>Nombre*</label>
                  <input
                    type="text"
                    id="nombre"
                    name="nombre"
                    value={usuario.nombre}
                    onChange={handleInputChange}
                    className="main-input"
                  />
                </div>
                <div className="grow">
                  <label htmlFor="" className="">
                    Apellido
                  </label>
                  <input
                    type="text"
                    id="apellido"
                    name="apellido"
                    value={usuario.apellido}
                    onChange={handleInputChange}
                    className="main-input"
                  />
                </div>
              </div>
              <div>
                <label>Email</label>
                <input
                  readOnly={true}
                  type="text"
                  value={usuario.email}
                  className="main-input"
                />
              </div>
              <div>
                <label>Telefono</label>
                <input
                  type="number"
                  id="telefono"
                  name="telefono"
                  value={usuario.telefono}
                  onChange={handleInputChange}
                  className="main-input"
                />
              </div>
              <div>
                <label>Pais</label>
                <input
                  type="text"
                  id="pais"
                  name="pais"
                  value={usuario.pais}
                  onChange={handleInputChange}
                  className="main-input"
                />
              </div>
              <div>
                <label>Provincia</label>
                <input
                  type="text"
                  id="provincia"
                  name="provincia"
                  value={usuario.provincia}
                  onChange={handleInputChange}
                  className="main-input"
                />
              </div>
              <div>
                <label>Distrito</label>
                <input
                  type="text"
                  id="distrito"
                  name="distrito"
                  value={usuario.distrito}
                  onChange={handleInputChange}
                  className="main-input"
                />
              </div>
              <div>
                <label>Barrio</label>
                <input
                  type="text"
                  id="barrio"
                  name="barrio"
                  value={usuario.barrio}
                  onChange={handleInputChange}
                  className="main-input"
                />
              </div>
              <div>
                <label>Dirección</label>
                <input
                  type="text"
                  id="direccion"
                  name="direccion"
                  value={usuario.direccion}
                  onChange={handleInputChange}
                  className="main-input"
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default UsuarioView;
