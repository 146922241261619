import { useEffect, useState } from "react";
import axios from "axios";
import ProfileForm from "./Form";
import AWS from "aws-sdk";
import ProfilePicture from "../../misc/ProfilePicture";
import Cefamoneda from "../../../../../icons/cefamoneda";

const s3 = new AWS.S3({
  accessKeyId: `${process.env.REACT_APP_AWS_KEYID}`,
  secretAccessKey: `${process.env.REACT_APP_AWS_ACCESSKEY}`,
});
s3.config.update({ signatureVersion: "v4" });

interface Usuario {
  nombre: string;
  apellido: string;
  email: string;
  pais: string;
  canton: string;
  distrito: string;
  barrio: string;
  direccion: string;
  puntos: number;
}
const Profile = () => {
  const [usuario, setUsuario] = useState<Usuario>({} as Usuario);
  const [foto, setFoto] = useState("");

  const getUser = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/usuarios/${sessionStorage.getItem(
          "user_id"
        )}`,
        {
          headers: {
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );

      setUsuario(response.data);
      const paramsS3 = {
        Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
        Key: `${response.data.foto}`,
        Expires: 60 * 60,
      };
      console.log(response.data);
      s3.getSignedUrl("getObject", paramsS3, (err, url) => {
        if (err) {
          console.error(err);
        } else {
          setFoto(url);
        }
      });
    } catch (error) {
      if (error === "Error: Request failed with status code 404") {
        console.log("No existe el usuario");
      }
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <div className="flex flex-col h-screen">
      <div className="flex m-2 ">
        <div className="flex gap-1">
          <div>{usuario.puntos?.toLocaleString()}</div>
          <Cefamoneda />
        </div>
      </div>
      <div>
        <div className="text-center mb-2">
          {usuario.nombre} {usuario.apellido}
        </div>

        <div className="m-auto w-fit py-4">
          <ProfilePicture image={foto} name={usuario.nombre} />
        </div>
      </div>

      <ProfileForm
        foto={foto}
        nombre={usuario.nombre}
        apellido={usuario.apellido}
        email={usuario.email}
        pais={usuario.pais}
        canton={usuario.canton}
        distrito={usuario.distrito}
        barrio={usuario.barrio}
        direccion={usuario.direccion}
      />
      <div className="p-2"></div>
    </div>
  );
};

export default Profile;
