import React, { useState, useEffect } from "react";
import ArrowDown from "../../../../../icons/arrowDown";
import ArrowUp from "../../../../../icons/arrowUp";
import Close from "../../../../../icons/close";
import axios from "axios";
import Swal from "sweetalert2";

interface Employee {
  employeeId: string;
  employeeName: string;
  participating: boolean;
}

interface Farmacia {
  farmaciaId: string;
  farmaciaName: string;
  employees: Employee[];
  isOpen: boolean;
}

interface Props {
  retoAssignmentView: boolean;
  setRetoAssignmentView: React.Dispatch<React.SetStateAction<boolean>>;
  retoViewID: number;
}

const FarmaciaDropdown = ({
  retoAssignmentView,
  setRetoAssignmentView,
  retoViewID,
}: Props) => {
  const [data, setData] = useState<Farmacia[]>([]);
  const [farmacias, setFarmacias] = useState<Farmacia[]>([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    retrieveEmployees();
  }, []);

  useEffect(() => {
    const initialData = farmacias.map((farmacia) => ({
      ...farmacia,
      isOpen: false,
    }));
    setData(initialData);
  }, [farmacias]);

  const toggleFarmaciaOpen = (fIndex: number) => {
    const newData = data.map((farmacia, index) => {
      if (index === fIndex) {
        return { ...farmacia, isOpen: !farmacia.isOpen };
      }
      return farmacia;
    });
    setData(newData);
  };

  const retrieveEmployees = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/farmacias/usuarios`,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );
      setFarmacias(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectAll = (checked: boolean) => {
    const newData = data.map((farmacia) => ({
      ...farmacia,
      employees: farmacia.employees.map((employee) => ({
        ...employee,
        participating: checked,
      })),
    }));

    setData(newData);
  };

  const handleFarmaciaCheckbox = (farmaciaIndex: number, checked: boolean) => {
    const originalFarmaciaIndex = searchTerm
      ? data.findIndex(
          (farmacia) =>
            farmacia.farmaciaId === filteredData[farmaciaIndex].farmaciaId
        )
      : farmaciaIndex;

    const newData = data.map((farmacia, index) => {
      if (index === originalFarmaciaIndex) {
        return {
          ...farmacia,
          employees: farmacia.employees.map((employee) => ({
            ...employee,
            participating: checked,
          })),
        };
      }
      return farmacia;
    });
    setData(newData);
  };

  const handleEmployeeCheckbox = (
    farmaciaIndex: number,
    employeeIndex: number,
    checked: boolean
  ) => {
    const originalFarmaciaIndex = searchTerm
      ? data.findIndex(
          (farmacia) =>
            farmacia.farmaciaId === filteredData[farmaciaIndex].farmaciaId
        )
      : farmaciaIndex;

    const newData = data.map((farmacia, fIndex): Farmacia => {
      if (fIndex === originalFarmaciaIndex) {
        return {
          ...farmacia,
          employees: farmacia.employees.map((employee, eIndex): Employee => {
            if (eIndex === employeeIndex) {
              return { ...employee, participating: checked };
            }
            return employee;
          }),
        };
      }
      return farmacia;
    });

    setData(newData);
  };

  const handleSearchChange = (event: any) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = searchTerm
    ? data.filter((farmacia) =>
        farmacia.farmaciaName.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : data;

  const submitAssignment = async () => {
    try {
      Swal.fire({
        title: "Procesando...",
        text: "No cierre esta ventana.",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/usuario_retos`,
        { farmacias: data, retoID: retoViewID },
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );
      Swal.fire({
        icon: "success",
        title: "Asignación exitosa",
        showConfirmButton: false,
        timer: 1500,
      });
      setRetoAssignmentView(!retoAssignmentView);
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Ha ocurrido un error",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  return (
    <>
      {retoAssignmentView ? (
        <div
          className={`bg-white absolute md:relative h-full p-6 ${
            retoAssignmentView ? "animate-slide-in" : "animate-slide-out"
          }`}
        >
          <div className="flex gap-2 items-center justify-center">
            <input
              type="text"
              placeholder="Buscar farmacia..."
              onChange={handleSearchChange}
              value={searchTerm}
              className="main-input mb-2"
            />
            <button className="cefa-button my-2" onClick={submitAssignment}>
              Asignar
            </button>
            <button
              onClick={() => {
                setRetoAssignmentView(!retoAssignmentView);
              }}
            >
              <Close />
            </button>
          </div>
          <input
            className="grow"
            type="checkbox"
            onChange={(e) => handleSelectAll(e.target.checked)}
            checked={data.every((farmacia) =>
              farmacia.employees.every((employee) => employee.participating)
            )}
          />
          <label htmlFor="" className="ml-2">
            Todos
          </label>
          <div
            className="overflow-auto bg-white pb-8 md:pb-0"
            style={{ maxHeight: "calc(78vh)" }}
          >
            {filteredData.map((farmacia, fIndex) => (
              <div key={farmacia.farmaciaId} className="farmacia-container">
                <div className="farmacia-header">
                  <div className="flex gap-2">
                    <input
                      type="checkbox"
                      onChange={(e) =>
                        handleFarmaciaCheckbox(fIndex, e.target.checked)
                      }
                      checked={farmacia.employees.every((e) => e.participating)}
                    />
                    <p>{farmacia.farmaciaName}</p>
                  </div>
                  <button onClick={() => toggleFarmaciaOpen(fIndex)}>
                    {farmacia.isOpen ? <ArrowUp /> : <ArrowDown />}
                  </button>
                </div>
                <div
                  className="farmacia-employees"
                  style={{ maxHeight: farmacia.isOpen ? "1000px" : "0" }}
                >
                  {farmacia.employees.map((employee, eIndex) => (
                    <div key={employee.employeeId} className="employee-item">
                      <input
                        type="checkbox"
                        checked={employee.participating}
                        onChange={(e) =>
                          handleEmployeeCheckbox(
                            fIndex,
                            eIndex,
                            e.target.checked
                          )
                        }
                      />
                      <p>{employee.employeeName}</p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default FarmaciaDropdown;
