import { useEffect, useState } from "react";
import Trash from "../../../../../icons/trash";
import axios from "axios";
import Swal from "sweetalert2";

interface Props {
  productListProps: any[];
  setView: any;
  view: boolean;
  ventaComprobanteID: number;
}
interface Producto {
  id: number;
  articulo: string;
  nombre: string;
  producto_id: number;
  cantidad: number;
  puntos: number;
  unidad: string;
  cantidad_por_caja: number;
}

const EditList = ({
  productListProps,
  view,
  ventaComprobanteID,
  setView,
}: Props) => {
  const [allProducts, setAllProducts] = useState<any[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<Producto[]>([]);
  const [tempProducto, setTempProducto] = useState<any>();

  async function getProductos() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/productos/all`,
        {
          headers: {
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );

      setAllProducts(response.data);
    } catch (error) {}
  }
  function removeProductoByArticulo(
    articuloToRemove: string,
    unidadToRemove: string
  ) {
    const filteredProductos = selectedProducts.filter(
      (producto: any) =>
        producto.articulo !== articuloToRemove ||
        producto.unidad !== unidadToRemove
    );
    setSelectedProducts(filteredProductos);
  }

  const newProduct = () => {
    if (
      tempProducto.producto_id === undefined ||
      tempProducto.producto_id === 0 ||
      tempProducto.unidad === ""
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Por favor, seleccione un producto.",
      });
      return;
    }
    const selectedProduct = allProducts.find(
      (product) => product.producto_id === tempProducto.producto_id
    );

    if (selectedProduct) {
      setSelectedProducts((prevSelectedProducts: any) => {
        const existingProductIndex = prevSelectedProducts.findIndex(
          (p: any) =>
            p.producto_id === tempProducto.producto_id &&
            p.unidad === tempProducto.unidad
        );
        if (existingProductIndex >= 0) {
          const newQuantityType = tempProducto.unidad;
          const newQuantity =
            Number(prevSelectedProducts[existingProductIndex].cantidad) +
            Number(tempProducto.cantidad);

          if (newQuantity <= 0) {
            return prevSelectedProducts.filter(
              (p: any) => p.producto_id !== tempProducto.producto_id
            );
          }
          const updatedSelectedProducts = [...prevSelectedProducts];
          updatedSelectedProducts[existingProductIndex] = {
            ...updatedSelectedProducts[existingProductIndex],
            cantidad: newQuantity,
            articulo: updatedSelectedProducts[existingProductIndex].articulo,
            unidad: newQuantityType,
          };

          return updatedSelectedProducts;
        } else {
          if (!selectedProduct.articulo) {
            console.error("Product data is incomplete!");
            return prevSelectedProducts;
          }

          return [
            ...prevSelectedProducts,
            {
              producto_id: selectedProduct.producto_id,
              nombre: selectedProduct.nombre,
              cantidad: Number(tempProducto.cantidad),
              articulo: selectedProduct.articulo,
              unidad: tempProducto.unidad,
            },
          ];
        }
      });
    } else {
      console.error("Product not found!");
    }
  };

  const handleProductoChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const selected_producto = event.target.value;
    const foundProduct = allProducts.find(
      (producto) => producto.nombre === selected_producto
    );

    if (!foundProduct) {
      return;
    }

    if (foundProduct.cantidad_por_caja === 0) {
      setTempProducto((prevState: any) => ({
        ...prevState,
        unidad: "Caja",
        producto_id: foundProduct.producto_id,
        nombre: foundProduct.nombre,
        cantidad_por_caja: foundProduct.cantidad_por_caja,
      }));
    } else {
      setTempProducto((prevState: any) => ({
        ...prevState,
        nombre: foundProduct.nombre,
        producto_id: foundProduct.producto_id,
        cantidad_por_caja: foundProduct.cantidad_por_caja,
        unidad: "Unidad",
      }));
    }
  };

  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = event.target;

    setTempProducto((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    Swal.fire({
      title: "Procesando...",
      text: "No cierre esta ventana.",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      if (selectedProducts.length === 0 || ventaComprobanteID === undefined) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Por favor, llene la informacion necesaria.",
        });
        return;
      }
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/venta_comprobante/update`,
        {
          products: selectedProducts,
          ventaComprobanteID: ventaComprobanteID,
        },
        { headers: { "auth-token": `${sessionStorage.getItem("token")}` } }
      );
      setView(!view);
      Swal.fire({
        icon: "success",
        title: "Éxito",
        text: "El comprobante ha sido modificado.",
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ha ocurrido un error al enviar los datos.",
      });
    }
  };

  useEffect(() => {
    const unidadSelect = document.getElementById("unidad") as HTMLSelectElement;
    if (unidadSelect) {
      unidadSelect.value =
        tempProducto && tempProducto.cantidad_por_caja !== 0
          ? "Unidad"
          : "Caja";
    }
  }, [tempProducto]);

  useEffect(() => {
    getProductos();
    setSelectedProducts(productListProps);
  }, [view]);

  return (
    <>
      {view ? (
        <div>
          <div>
            <hr className="py-2" />
            <button
              className="cefa-button"
              onClick={handleSubmit}
              type="button"
            >
              Guardar
            </button>
            <form action="" className="flex flex-col gap-2">
              <div>
                <label>Producto</label>
                <input
                  type="text"
                  id="producto_id"
                  name="producto_id"
                  onChange={handleProductoChange}
                  list="names"
                  className="main-input"
                />
                <datalist id="names">
                  {allProducts.map((producto) => (
                    <option
                      key={`${producto.producto_id}`}
                      value={`${producto.nombre}`}
                    />
                  ))}
                </datalist>
              </div>
              <div>
                <label>Unidad*</label>
                <select
                  id="unidad"
                  name="unidad"
                  onChange={handleInputChange}
                  className="custom-select main-input"
                >
                  <option value="Caja">Caja</option>
                  {tempProducto && tempProducto.cantidad_por_caja !== 0 && (
                    <option value="Unidad">Unidad</option>
                  )}
                </select>
              </div>
              <div>
                <label>Cantidad*</label>
                <input
                  type="number"
                  id="cantidad"
                  name="cantidad"
                  onChange={handleInputChange}
                  className="main-input"
                />
              </div>
              <button
                className="cefa-button"
                onClick={newProduct}
                type="button"
              >
                Agregar Producto
              </button>
            </form>
          </div>

          <div
            className="overflow-auto bg-white pb-8 md:pb-0"
            style={{ maxHeight: "calc(65vh)" }}
          >
            <table className="w-full">
              <thead className="bg-gray-50 top-0 sticky">
                <tr className="table-head">
                  <th className="text-left">Nombre</th>
                  <th className="text-left">Cantidad</th>
                  <th className="text-center">Unidad</th>
                  <th className="text-center">Acciones</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {selectedProducts.length === 0 ? (
                  <tr className="hover:bg-slate-300">
                    <td className="text-center" colSpan={6}>
                      No hay productos disponibles
                    </td>
                  </tr>
                ) : (
                  selectedProducts.map((producto) => (
                    <tr
                      className="hover:bg-slate-100 table-body"
                      key={producto.producto_id}
                    >
                      <td className="text-left">{producto.nombre}</td>
                      <td className="text-left">{producto.cantidad}</td>
                      <td className="text-left">{producto.unidad}</td>
                      <td>
                        <div className="m-auto w-fit">
                          <button
                            onClick={() =>
                              removeProductoByArticulo(
                                producto.articulo,
                                producto.unidad
                              )
                            }
                          >
                            <Trash size={15} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default EditList;
