import { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
import PasswordRequirements from "./PasswordRequirements";

const Invitation = () => {
  //State for User information
  const [password, setPassword] = useState("");

  //Get the premio id from the url
  let params = useParams();

  const handleSignup = async (error: any) => {
    error.preventDefault();
    const invitationToken = params.token;
    const userEmail = params.email;
  };

  return (
    <>
      <div>
        <form
          onSubmit={handleSignup}
          className="max-w-lg m-auto w-full flex flex-col p-4 gap-2"
        >
          <h2 className="text-2xl">Bienvenido</h2>
          <p>Has sido invitado a utilizar PREM!</p>
          <input
            type="password"
            placeholder="Contraseña"
            value={password}
            className="block w-full h-12 bg-[#F3F3F3] rounded shadow-md pl-4 read-only:bg-gray-400 placeholder-black"
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            type="submit"
            className="bg-green-700 rounded py-2 text-white"
          >
            Registrarse
          </button>
          <PasswordRequirements password={password} />
        </form>
      </div>
    </>
  );
};

export default Invitation;
