import axios from "axios";
import { useEffect, useState } from "react";
import AWS from "aws-sdk";
import ImageUploader from "../../misc/ImageUploader";
import Close from "../../../../../icons/close";
import Swal from "sweetalert2";

interface Producto {
  producto_id: number;
  nombre: string;
}

interface Props {
  view: boolean;
  setView: React.Dispatch<React.SetStateAction<boolean>>;
  counter: number;
  setCounter: React.Dispatch<React.SetStateAction<number>>;
}

interface Reto {
  nombre: string;
  descripcion: string;
  producto_id: number;
  cantidad: number;
  puntos: number;
  fecha_inicio: Date;
  fecha_fin: Date;
}

const s3 = new AWS.S3({
  accessKeyId: `${process.env.REACT_APP_AWS_KEYID}`,
  secretAccessKey: `${process.env.REACT_APP_AWS_ACCESSKEY}`,
});
s3.config.update({ signatureVersion: "v4" });

const AdminNewReto = ({ view, setView, setCounter, counter }: Props) => {
  const [productos, setProductos] = useState<Producto[]>([]);
  const [producto_id, setProducto_id] = useState(0);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [reto, setReto] = useState<Reto>({} as Reto);
  const [isClosing, setIsClosing] = useState(false);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setReto({ ...reto, [name]: value });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      Swal.fire({
        title: "Procesando...",
        text: "No cierre esta ventana.",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      const date = new Date();
      let tempFechaInicio = new Date(reto.fecha_inicio!);
      let tempFechaFin = new Date(reto.fecha_fin!);
      let data;
      if (selectedFile) {
        data = {
          nombre: reto.nombre,
          descripcion: reto.descripcion,
          puntos: reto.puntos,
          producto_id: producto_id,
          cantidad: reto.cantidad,
          fecha_inicio: tempFechaInicio,
          fecha_fin: tempFechaFin,
          imagen: `retos/${date}-${selectedFile?.name}`,
        };
      } else {
        data = {
          nombre: reto.nombre,
          descripcion: reto.descripcion,
          puntos: reto.puntos,
          producto_id: producto_id,
          cantidad: reto.cantidad,
          fecha_inicio: tempFechaInicio,
          fecha_fin: tempFechaFin,
          imagen: "",
        };
      }
      if (selectedFile) {
        const uploadParams = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `retos/${date}-${selectedFile?.name}`,
          Body: selectedFile,
        };

        await s3.upload(uploadParams).promise();
      }
      await axios.post(`${process.env.REACT_APP_API_URL}/api/retos`, data, {
        headers: {
          "Content-Type": "application/json",
          "auth-token": `${sessionStorage.getItem("token")}`,
        },
      });
      setCounter(counter + 1);
      setReto({} as Reto);
      setView(!view);
      Swal.fire("Reto creado correctamente", "", "success");
    } catch (error) {
      Swal.fire("Ha ocurrido un error", "", "error");
      console.error(error);
    }
  };

  const handleProductoChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const selected_producto = event.target.value;
    const selected_producto_id = productos.find(
      (producto) => producto.nombre === selected_producto
    );

    setProducto_id(selected_producto_id?.producto_id!);
  };

  const getProductos = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/productos/active`,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );
      setProductos(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getProductos();
  }, []);

  return (
    <>
      {view || isClosing ? (
        <div className={`${view ? "bg-drawer" : "hidden"}`}>
          <div className="grow"></div>
          <div
            className={`h-full bg-white p-2 w-screen md:w-1/3 md:min-w-fit min-w-full overflow-auto px-6 ${
              isClosing ? "animate-slide-out" : "animate-slide-in"
            }`}
          >
            <div className="w-full sticky top-0 bg-white flex items-center my-4">
              <p className="text-lg">
                <strong>Nuevo Reto</strong>
              </p>
              <div className="grow"></div>
              <button onClick={() => setView(!view)}>
                <Close />
              </button>
            </div>

            <form
              action=""
              className="grow w-full grid gap-2"
              onSubmit={handleSubmit}
            >
              <div className="block">
                <label>Nombre*</label>
                <input
                  type="text"
                  id="nombre"
                  name="nombre"
                  onChange={handleInputChange}
                  className="main-input"
                  required
                />
              </div>
              <div className="block">
                <label>Descripcion</label>
                <textarea
                  id="descripcion"
                  name="descripcion"
                  className="main-input h-24 p-2"
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label>Producto*</label>
                <input
                  type="text"
                  id="producto_id"
                  name="producto_id"
                  onChange={handleProductoChange}
                  list="names"
                  className="main-input"
                  required
                />
                <datalist id="names">
                  {productos.map((producto) => (
                    <option
                      key={`${producto.producto_id}`}
                      value={`${producto.nombre}`}
                    />
                  ))}
                </datalist>
              </div>
              <div className="block">
                <label>Cantidad de unidades a vender*</label>
                <input
                  type="number"
                  id="cantidad"
                  name="cantidad"
                  onChange={handleInputChange}
                  className="main-input"
                  required
                />
              </div>
              <div className="block">
                <label>Puntos*</label>
                <input
                  type="number"
                  id="puntos"
                  name="puntos"
                  onChange={handleInputChange}
                  className="main-input"
                  required
                />
              </div>
              <div className="flex gap-4">
                <div className="grow">
                  <label>Fecha De Inicio*</label>
                  <input
                    type="date"
                    id="fecha_inicio"
                    name="fecha_inicio"
                    onChange={handleInputChange}
                    className="main-input"
                    required
                  />
                </div>
                <div className="grow">
                  <label>Fecha De Finalizacion*</label>
                  <input
                    type="date"
                    id="fecha_fin"
                    name="fecha_fin"
                    onChange={handleInputChange}
                    className="main-input"
                    required
                  />
                </div>
              </div>
              <p>Imagen</p>
              <ImageUploader
                setSelectedFile={setSelectedFile}
                inputId="RetoNew"
              />
              <button className={`cefa-button`}>Crear Reto</button>
            </form>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default AdminNewReto;
