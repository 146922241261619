import { useState } from "react";
import PedidosList from "./List";

const AdminPedidos = () => {
  const [counter, setCounter] = useState(0);

  return (
    <div className="flex flex-col grow">
      <div className="flex items-center py-4">
        <p className="font-bold grow text-2xl">
          <strong>Pedidos</strong>
        </p>
      </div>
      <div className="flex-grow overflow-auto">
        <PedidosList counter={counter} setCounter={setCounter} />
      </div>
    </div>
  );
};

export default AdminPedidos;
