import { useEffect, useState, useRef, useCallback } from "react";

interface CarouselProps {
  images: {
    image: string;
    external_url: string;
  }[];
}

const Carousel = ({ images }: CarouselProps) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  console.log(images);
  useEffect(() => {
    startSlideTimer();
    return () => {
      clearSlideTimer();
    };
  }, [images.length]);

  const formatUrl = (url: any) => {
    if (!/^(?:f|ht)tps?\:\/\//.test(url) && !url.startsWith("//")) {
      return `https://${url}`;
    }
    return url;
  };

  const startSlideTimer = useCallback(() => {
    clearSlideTimer();
    intervalRef.current = setInterval(() => {
      setActiveIndex((current) => (current + 1) % images.length);
    }, 5000);
  }, [images.length]);

  const clearSlideTimer = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  }, []);

  const nextSlide = useCallback(() => {
    setActiveIndex((current) => (current + 1) % images.length);
    startSlideTimer();
  }, [images.length, startSlideTimer]);

  const prevSlide = useCallback(() => {
    setActiveIndex((current) =>
      current === 0 ? images.length - 1 : current - 1
    );
    startSlideTimer();
  }, [images.length, startSlideTimer]);

  return (
    <div className="carousel relative max-w-2xl m-auto">
      {images.map((item, index) => (
        <a
          key={index}
          href={formatUrl(item.external_url)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={item.image}
            className={`carousel-image ${
              index === activeIndex ? "active rounded-2xl" : "hidden"
            } max-w-2xl m-auto`}
            alt={`Carousel Image ${index + 1}`}
          />
        </a>
      ))}

      <button
        className="absolute left-3 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 rounded-full p-2 cursor-pointer"
        onClick={prevSlide}
        aria-label="Previous slide"
      >
        &#10094;
      </button>
      <button
        className="absolute right-3 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 rounded-full p-2 cursor-pointer"
        onClick={nextSlide}
        aria-label="Next slide"
      >
        &#10095;
      </button>
    </div>
  );
};

export default Carousel;
