interface Props {
  password: string;
}
const PasswordRequirements = ({ password }: Props) => {
  const minLength = 8;
  const hasLowercase = /[a-z]/.test(password);
  const hasUppercase = /[A-Z]/.test(password);
  const hasNumber = /[0-9]/.test(password);
  const hasSpecialCharacter = /[!@#$%^&*]/.test(password);

  const requirements = [
    {
      text: "La longitud de la contraseña debe ser de al menos 8 caracteres.",
      isValid: password.length >= minLength,
    },
    {
      text: "Las contraseñas deben incluir al menos una letra minúscula.",
      isValid: hasLowercase,
    },
    {
      text: "Las contraseñas deben incluir al menos una letra mayúscula.",
      isValid: hasUppercase,
    },
    {
      text: "Las contraseñas deben incluir al menos un carácter numérico.",
      isValid: hasNumber,
    },
    {
      text: "Las contraseñas deben incluir al menos un carácter especial (por ejemplo, !@#$%^&*).",
      isValid: hasSpecialCharacter,
    },
  ];

  const strength = requirements.reduce(
    (acc, req) => acc + (req.isValid ? 1 : 0),
    0
  );
  const strengthPercentage = (strength / requirements.length) * 100;

  let strengthColor = "red";
  if (strengthPercentage >= 75) {
    strengthColor = "green";
  } else if (strengthPercentage >= 65) {
    strengthColor = "yellow";
  }

  return (
    <>
      <div className="relative pt-1">
        <div
          className={`overflow-hidden h-2 mb-4 text-xs flex rounded bg-${strengthColor}-200`}
        >
          <div
            style={{ width: `${strengthPercentage}%` }}
            className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-${strengthColor}-500`}
          ></div>
        </div>
      </div>
      <ul className="flex flex-col gap-2">
        {requirements.map((req, index) => (
          <li
            key={index}
            className={`${req.isValid ? "text-green-500" : "text-red-500"}`}
          >
            {req.text}
          </li>
        ))}
      </ul>
    </>
  );
};

export default PasswordRequirements;
