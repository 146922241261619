import List from "./List";

const Comprobantes = () => {
  return (
    <div className="flex flex-col w-full">
      <div className="flex items-center py-4">
        <p className="font-bold grow text-2xl">
          <strong>Comprobantes De Retos</strong>
        </p>
      </div>
      <div className="flex-grow overflow-hidden">
        <List />
      </div>
    </div>
  );
};

export default Comprobantes;
