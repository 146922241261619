import UsuariosList from "./List";
import { useEffect, useState } from "react";
import New from "./New";
import Add from "../../../../../icons/add";

const AdminUsuarios = () => {
  const [view, setView] = useState(false);
  const [counter, setCounter] = useState(0);

  useEffect(() => {}, [counter]);

  return (
    <div className="flex flex-col w-full">
      <div className="flex items-center py-4">
        <p className="font-bold grow text-2xl">
          <strong>Usuarios</strong>
        </p>
        <div>
          <button onClick={() => setView(!view)} className="cefa-button">
            <p>Agregar Usuario</p>
            <Add />
          </button>
        </div>
      </div>
      <New
        view={view}
        setView={setView}
        counter={counter}
        setCounter={setCounter}
      />
      <div className="flex-grow overflow-auto">
        <UsuariosList counter={counter} setCounter={setCounter} />
      </div>
    </div>
  );
};

export default AdminUsuarios;
