import axios from "axios";
import React, { useEffect, useState } from "react";
import ViewIcon from "../../../../../icons/view";
import { formatToMMDDYYYY } from "../../../../../utils/dates";
import View from "./View";

interface ventaComprobante {
  venta_comprobante_id: number;
  fecha_creacion: string;
  usuario: string;
  estado: string;
}

const List = () => {
  const [ventaComprobantes, setVentaComprobantes] = useState<
    ventaComprobante[]
  >([]);
  const [ventaComprobanteView, setVentaComprobanteView] = useState(false);
  const [ventaComprobanteViewID, setVentaComprobanteViewID] =
    useState<number>(0);
  const [searchText, setSearchText] = useState("");
  const [filterDate, setFilterDate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [counter, setCounter] = useState(0);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("");

  const handleSort = (field: any) => {
    const order = field === sortField && sortOrder === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortOrder(order);
    getVentaComprobantes(1);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const handleFilterDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterDate(event.target.value);
  };

  const getVentaComprobantes = async (
    page = 1,
    searchText = "",
    filterDate = ""
  ) => {
    try {
      const query = `page=${page}&search=${encodeURIComponent(
        searchText
      )}&date=${encodeURIComponent(
        filterDate
      )}&sortField=${sortField}&sortOrder=${sortOrder}`;

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/venta_comprobante/?${query}`,
        {
          headers: { "auth-token": sessionStorage.getItem("token") },
        }
      );
      setCurrentPage(response.data.pageInfo.currentPage);
      setTotalPages(response.data.pageInfo.totalPages);
      setVentaComprobantes(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const generatePageNumbers = () => {
    return Array.from(
      { length: 5 },
      (_, index) => currentPage - 2 + index
    ).filter((page) => page >= 1 && page <= totalPages);
  };

  const handlePageChange = (page: any) => {
    setCurrentPage(page);
    getVentaComprobantes(page, searchText, filterDate);
  };

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      getVentaComprobantes(1, searchText, filterDate);
    }, 300);

    return () => clearTimeout(delayDebounce);
  }, [searchText, filterDate]);

  useEffect(() => {
    getVentaComprobantes(currentPage, searchText, filterDate);
  }, [counter]);

  return (
    <>
      <p className="font-bold text-2xl my-4">
        <strong>Comprobantes De Ventas</strong>
      </p>
      <div className="flex flex-col gap-3">
        <input
          className="main-input"
          type="text"
          placeholder="Buscar..."
          value={searchText}
          onChange={handleSearch}
        />
        <input
          className="main-input"
          type="date"
          placeholder="Fecha..."
          value={filterDate}
          onChange={handleFilterDate}
        />
      </div>
      <div
        className="overflow-auto bg-white pb-8 md:pb-0"
        style={{ maxHeight: "calc(65vh)" }}
      >
        <table className="w-full">
          <thead className="bg-gray-50 top-0 sticky">
            <tr className="table-head">
              <th className="text-left">Estado</th>
              <th
                className="text-left"
                onClick={() => handleSort("vc.venta_comprobante_id")}
              >
                ID
                {sortField === "vc.venta_comprobante_id" &&
                  (sortOrder === "asc" ? "↑" : "↓")}
              </th>
              <th className="text-left" onClick={() => handleSort("usuario")}>
                Usuario
                {sortField === "usuario" && (sortOrder === "asc" ? "↑" : "↓")}
              </th>
              <th
                className="text-left"
                onClick={() => handleSort("vc.fecha_creacion")}
              >
                Fecha de creacion
                {sortField === "vc.fecha_creacion" &&
                  (sortOrder === "asc" ? "↑" : "↓")}
              </th>
              <th className="text-center">Acciones</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {ventaComprobantes.length === 0 ? (
              <tr className="hover:bg-slate-300">
                <td className="text-center" colSpan={6}>
                  No hay pedidos disponibles
                </td>
              </tr>
            ) : (
              ventaComprobantes.map((comprobante) => (
                <tr
                  className="hover:bg-slate-100 table-body"
                  key={comprobante.venta_comprobante_id}
                >
                  <td>{comprobante.estado}</td>
                  <td className="text-left">
                    {comprobante.venta_comprobante_id}
                  </td>
                  <td className="text-left">{comprobante.usuario}</td>
                  <td className="text-left">
                    {formatToMMDDYYYY(comprobante.fecha_creacion)}
                  </td>
                  <td>
                    <div className="flex gap-2 items-centers justify-center">
                      <button
                        onClick={() => {
                          setVentaComprobanteView(!ventaComprobanteView);
                          setVentaComprobanteViewID(
                            comprobante.venta_comprobante_id
                          );
                        }}
                      >
                        <ViewIcon />
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div>
        {ventaComprobantes.length > 0 && (
          <div className="flex justify-center items-center gap-4 mt-4">
            <button
              className={`${
                currentPage === 1 ? "hidden" : "cefa-button w-fit"
              }`}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              Anterior
            </button>

            <div className="flex gap-2">
              {generatePageNumbers().map((page) => (
                <button
                  key={page}
                  className={`cefa-button ${
                    currentPage === page ? "active" : ""
                  }`}
                  onClick={() => handlePageChange(page)}
                >
                  {page}
                </button>
              ))}
            </div>

            <button
              className={`${
                currentPage === totalPages ? "hidden" : "cefa-button w-fit"
              }`}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Siguiente
            </button>
          </div>
        )}
      </div>
      <View
        counter={counter}
        setCounter={setCounter}
        ventaComprobanteView={ventaComprobanteView}
        setVentaComprobanteView={setVentaComprobanteView}
        ventaComprobanteID={ventaComprobanteViewID}
        setVentaComprobanteViewID={setVentaComprobanteViewID}
      />
    </>
  );
};

export default List;
