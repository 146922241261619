import axios from "axios";
import Carousel from "../../misc/Carousel";
import { useEffect, useState } from "react";
import AWS from "aws-sdk";
import VideoPlayer from "../../misc/Video";

const Marca = () => {
  const [videoOne, setVideoOne] = useState("");
  const [videoTwo, setVideoTwo] = useState("");
  const [carouselOneImages, setCarouselOneImages] = useState<any>([]);
  const [carouselTwoImages, setCarouselTwoImages] = useState<any>([]);

  const addImageToCarousel = (newImageUrl: any) => {
    setCarouselOneImages((prevImages: any) => [...prevImages, newImageUrl]);
  };

  const addImageToCarouselTwo = (newImageUrl: any) => {
    setCarouselTwoImages((prevImages: any) => [...prevImages, newImageUrl]);
  };

  const getCarouselOne = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/publicidad/carouselOne`
      );

      let tempImageOne = response.data.filter(
        (image: any) => image.placement === "CarouselOnePictureOne"
      );
      let tempImageTwo = response.data.filter(
        (image: any) => image.placement === "CarouselOnePictureTwo"
      );

      let tempImageThree = response.data.filter(
        (image: any) => image.placement === "CarouselOnePictureThree"
      );

      let tempImageFour = response.data.filter(
        (image: any) => image.placement === "CarouselOnePictureFour"
      );

      let tempImageFive = response.data.filter(
        (image: any) => image.placement === "CarouselOnePictureFive"
      );

      if (tempImageOne) {
        let paramsS3 = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `${tempImageOne[0].link}`,
          Expires: 60 * 60,
        };

        s3.getSignedUrl("getObject", paramsS3, (err, url) => {
          if (err) {
            console.error(err);
          } else {
            addImageToCarousel({
              image: url,
              external_url: tempImageOne[0].external_url,
            });
          }
        });
      }

      if (tempImageTwo) {
        let paramsS3 = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `${tempImageTwo[0].link}`,
          Expires: 60 * 60,
        };
        s3.getSignedUrl("getObject", paramsS3, (err, url) => {
          if (err) {
            console.error(err);
          } else {
            addImageToCarousel({
              image: url,
              external_url: tempImageTwo[0].external_url,
            });
          }
        });
      }

      if (tempImageThree) {
        let paramsS3 = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `${tempImageThree[0].link}`,
          Expires: 60 * 60,
        };
        s3.getSignedUrl("getObject", paramsS3, (err, url) => {
          if (err) {
            console.error(err);
          } else {
            addImageToCarousel({
              image: url,
              external_url: tempImageThree[0].external_url,
            });
          }
        });
      }

      if (tempImageFour) {
        let paramsS3 = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `${tempImageFour[0].link}`,
          Expires: 60 * 60,
        };
        s3.getSignedUrl("getObject", paramsS3, (err, url) => {
          if (err) {
            console.error(err);
          } else {
            addImageToCarousel({
              image: url,
              external_url: tempImageFour[0].external_url,
            });
          }
        });
      }

      if (tempImageFive) {
        let paramsS3 = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `${tempImageFive[0].link}`,
          Expires: 60 * 60,
        };
        s3.getSignedUrl("getObject", paramsS3, (err, url) => {
          if (err) {
            console.error(err);
          } else {
            addImageToCarousel({
              image: url,
              external_url: tempImageFive[0].external_url,
            });
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCarouselTwo = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/publicidad/carouselTwo`
      );

      let tempImageOne = response.data.filter(
        (image: any) => image.placement === "CarouselTwoPictureOne"
      );
      let tempImageTwo = response.data.filter(
        (image: any) => image.placement === "CarouselTwoPictureTwo"
      );

      let tempImageThree = response.data.filter(
        (image: any) => image.placement === "CarouselTwoPictureThree"
      );

      let tempImageFour = response.data.filter(
        (image: any) => image.placement === "CarouselTwoPictureFour"
      );

      let tempImageFive = response.data.filter(
        (image: any) => image.placement === "CarouselTwoPictureFive"
      );

      if (tempImageOne) {
        let paramsS3 = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `${tempImageOne[0].link}`,
          Expires: 60 * 60,
        };

        s3.getSignedUrl("getObject", paramsS3, (err, url) => {
          if (err) {
            console.error(err);
          } else {
            addImageToCarouselTwo({
              image: url,
              external_url: tempImageOne[0].external_url,
            });
          }
        });
      }

      if (tempImageTwo) {
        let paramsS3 = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `${tempImageTwo[0].link}`,
          Expires: 60 * 60,
        };
        s3.getSignedUrl("getObject", paramsS3, (err, url) => {
          if (err) {
            console.error(err);
          } else {
            addImageToCarouselTwo({
              image: url,
              external_url: tempImageTwo[0].external_url,
            });
          }
        });
      }

      if (tempImageThree) {
        let paramsS3 = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `${tempImageThree[0].link}`,
          Expires: 60 * 60,
        };
        s3.getSignedUrl("getObject", paramsS3, (err, url) => {
          if (err) {
            console.error(err);
          } else {
            addImageToCarouselTwo({
              image: url,
              external_url: tempImageThree[0].external_url,
            });
          }
        });
      }

      if (tempImageFour) {
        let paramsS3 = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `${tempImageFour[0].link}`,
          Expires: 60 * 60,
        };
        s3.getSignedUrl("getObject", paramsS3, (err, url) => {
          if (err) {
            console.error(err);
          } else {
            addImageToCarouselTwo({
              image: url,
              external_url: tempImageFour[0].external_url,
            });
          }
        });
      }

      if (tempImageFive) {
        let paramsS3 = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `${tempImageFive[0].link}`,
          Expires: 60 * 60,
        };
        s3.getSignedUrl("getObject", paramsS3, (err, url) => {
          if (err) {
            console.error(err);
          } else {
            addImageToCarouselTwo({
              image: url,
              external_url: tempImageFive[0].external_url,
            });
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const s3 = new AWS.S3({
    accessKeyId: `${process.env.REACT_APP_AWS_KEYID}`,
    secretAccessKey: `${process.env.REACT_APP_AWS_ACCESSKEY}`,
  });
  s3.config.update({ signatureVersion: "v4" });

  const getVideos = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/publicidad/videos`,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );

      let tempVideoOne = response.data.filter(
        (image: any) => image.placement === "VideoOne"
      );
      let tempVideoTwo = response.data.filter(
        (image: any) => image.placement === "VideoTwo"
      );

      if (tempVideoOne.length > 0) {
        let paramsS3 = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `${tempVideoOne[0].link}`,
          Expires: 60 * 60,
        };
        s3.getSignedUrl("getObject", paramsS3, (err, url) => {
          if (err) {
            console.error(err);
          } else {
            setVideoOne(url);
          }
        });
      }

      if (tempVideoTwo.length > 0) {
        let paramsS3 = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `${tempVideoTwo[0].link}`,
          Expires: 60 * 60,
        };
        s3.getSignedUrl("getObject", paramsS3, (err, url) => {
          if (err) {
            console.error(err);
          } else {
            setVideoTwo(url);
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCarouselTwo();
    getCarouselOne();
    getVideos();
  }, []);

  return (
    <div className="overflow-auto h-screen flex flex-col gap-6 pb-6">
      <Carousel images={carouselOneImages} />
      <VideoPlayer videoUrl={videoOne} />
      <Carousel images={carouselTwoImages} />
      <VideoPlayer videoUrl={videoTwo} />
    </div>
  );
};

export default Marca;
