import { useEffect, useState } from "react";

interface Props {
  status: string;
  setStatus: () => void;
}

const StatusToggle = ({ status, setStatus }: Props) => {
  const [isActive, setIsActive] = useState(false);

  const toggleStatus = () => {
    setStatus();
    setIsActive(!isActive);
  };

  useEffect(() => {
    if (status === "Activo") setIsActive(true);
    else setIsActive(false);
  }, [status]);

  return (
    <label className="switch">
      <input type="checkbox" checked={isActive} onChange={toggleStatus} />
      <span className="slider round"></span>
    </label>
  );
};

export default StatusToggle;
