import { useEffect, useState } from "react";
import axios from "axios";
import ProfileForm from "./Form";
import AWS from "aws-sdk";
import Cefamoneda from "../../../../../icons/cefamoneda";

interface user {
  nombre: string;
  apellido: string;
  email: string;
  pais: string;
  canton: string;
  distrito: string;
  barrio: string;
  direccion: string;
  puntos: number;
}

const s3 = new AWS.S3({
  accessKeyId: `${process.env.REACT_APP_AWS_KEYID}`,
  secretAccessKey: `${process.env.REACT_APP_AWS_ACCESSKEY}`,
});
s3.config.update({ signatureVersion: "v4" });

const Profile = () => {
  const [foto, setFoto] = useState("");
  const [user, setUser] = useState<user>({} as user);

  const getUser = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/usuarios/${sessionStorage.getItem(
          "user_id"
        )}`,
        {
          headers: {
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );
      setUser(response.data);
      const paramsS3 = {
        Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
        Key: `${response.data.foto}`,
        Expires: 60 * 60,
      };
      s3.getSignedUrl("getObject", paramsS3, (err, url) => {
        if (err) {
          console.error(err);
        } else {
          setFoto(url);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <div className="h-screen flex flex-col">
      <div className="flex gap-1">
        <div>{user.puntos}</div>
        <Cefamoneda />
      </div>
      <div className="m-auto">
        <div className="text-center mb-2">
          {user.nombre} {user.apellido}
        </div>
        <img className="rounded-full w-24 h-24 m-auto" src={foto} alt="" />
      </div>
      <ProfileForm
        nombre={user.nombre}
        apellido={user.apellido}
        email={user.email}
        pais={user.pais}
        canton={user.canton}
        distrito={user.distrito}
        barrio={user.barrio}
        direccion={user.direccion}
      />
      <div className="p-2"></div>
    </div>
  );
};

export default Profile;
