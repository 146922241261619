import AWS from "aws-sdk";
import axios from "axios";
import React, { useState } from "react";
import ImageUploader from "../../misc/ImageUploader";
import Swal from "sweetalert2";

interface Props {
  reto_id: number;
  usuario_reto_id: number;
  producto_id: number;
}

const s3 = new AWS.S3({
  accessKeyId: `${process.env.REACT_APP_AWS_KEYID}`,
  secretAccessKey: `${process.env.REACT_APP_AWS_ACCESSKEY}`,
});
s3.config.update({ signatureVersion: "v4" });

interface Comprobante {
  numero_factura?: string;
  cantidad?: number;
  imagen?: string;
}

const MyRetoComprobante = ({
  reto_id,
  usuario_reto_id,
  producto_id,
}: Props) => {
  const [selectedFile, setSelectedFile] = useState<File>();
  const [selectedFileView, setSelectedFileView] = useState(false);

  const [comprobante, setComprobante] = useState<Comprobante>({
    numero_factura: "",
    cantidad: 0,
    imagen: "",
  });

  const newComprobante = async () => {
    try {
      Swal.fire({
        title: "Procesando...",
        text: "No cierre esta ventana.",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      if (selectedFile !== undefined) {
        const dateNow = Date.now() / 1000;
        const data = {
          usuario_reto_id: usuario_reto_id,
          numero_factura: comprobante?.numero_factura,
          cantidad: Number(comprobante?.cantidad),
          foto: `comprobantes/${dateNow}-${reto_id}-${selectedFile?.name}`,
        };

        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/reto_comprobantes/`,
          data,
          {
            headers: {
              "auth-token": `${sessionStorage.getItem("token")}`,
            },
          }
        );
        setComprobante({ numero_factura: "", cantidad: 0, imagen: "" });
        setSelectedFileView(!selectedFileView);
        setSelectedFile(undefined);

        const uploadParams = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `comprobantes/${dateNow}-${reto_id}-${selectedFile?.name}`,
          Body: selectedFile,
        };
        await s3.upload(uploadParams).promise();
        Swal.fire({
          icon: "success",
          title: "Comprobante Ingresado",
          text: "El comprobante ha sido ingresado con éxito",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Debe agregar una imagen",
        });
      }
    } catch (error: any) {
      console.error("An error occurred:", error);

      if (error.response) {
        const errorMessage =
          error.response.data.message || "Ha ocurrido un error";
        Swal.fire({
          icon: "error",
          title: "Error",
          text: errorMessage,
          confirmButtonText: "Aceptar",
          timer: 5000,
        });
      } else if (error.request) {
        Swal.fire({
          icon: "warning",
          title: "Error de Red",
          text: "No se pudo obtener respuesta del servidor.",
          confirmButtonText: "Reintentar",
          timer: 5000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.message || "Ha ocurrido un error desconocido",
          confirmButtonText: "Aceptar",
          timer: 5000,
        });
      }
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    newComprobante();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setComprobante((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="bg-white rounded w-full mb-4">
      <div className="flex flex-col">
        <form action="" className="flex flex-col gap-4" onSubmit={handleSubmit}>
          <div className="">
            <label htmlFor="" className="">
              Numero De Factura
            </label>
            <input
              required
              type="text"
              id="numero_factura"
              name="numero_factura"
              value={comprobante?.numero_factura || ""}
              onChange={handleInputChange}
              className="main-input"
            />
          </div>

          <div className="block">
            <label htmlFor="" className="">
              Cantidad Vendida
            </label>
            <input
              required
              type="number"
              id="cantidad"
              name="cantidad"
              value={comprobante?.cantidad || ""}
              onChange={handleInputChange}
              className="main-input"
            />
          </div>
          <ImageUploader
            setSelectedFile={setSelectedFile}
            inputId="UsuarioRetoNew"
          />
          <button className="cefa-button">Ingresar Comprobante</button>
        </form>
      </div>
    </div>
  );
};

export default MyRetoComprobante;
