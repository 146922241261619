import AWS from "aws-sdk";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import New from "./New";
import MyRetoProgressBar from "./MyRetoProgressBar";
import Close from "../../../../../icons/close";

//Configuration of AWS S3
const s3 = new AWS.S3({
  accessKeyId: `${process.env.REACT_APP_AWS_KEYID}`,
  secretAccessKey: `${process.env.REACT_APP_AWS_ACCESSKEY}`,
});
s3.config.update({ signatureVersion: "v4" });

interface Reto {
  usuario_reto_id: number;
  reto_id: number;
  reto_name: string;
  reto_fecha_inicio: string;
  reto_fecha_fin: string;
  reto_imagen: string;
  reto_puntos: number;
  reto_cantidad: number;
  reto_descripcion: string;
  cantidad_vendida: number;
  producto: string;
  producto_id: number;
  estado: string;
}
interface Props {
  retoView: boolean;
  setRetoView: React.Dispatch<React.SetStateAction<boolean>>;
  retoViewID: number;
  setCounter: React.Dispatch<React.SetStateAction<number>>;
}
const MyRetoView = ({
  retoViewID,
  retoView,
  setRetoView,
  setCounter,
}: Props) => {
  const [reto, setReto] = useState<Reto>();
  const [img, setImg] = useState<string>("");

  let params = useParams();

  const getReto = async (retoViewID: any) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/usuario_retos/${retoViewID}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          params: { usuario_id: params.id },
        }
      );
      setReto(response.data);
      const paramsS3 = {
        Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
        Key: `${response.data.reto_imagen}`,
        Expires: 60 * 60,
      };
      s3.getSignedUrl("getObject", paramsS3, (err, url) => {
        if (err) {
          console.error(err);
        } else {
          setImg(url);
        }
      });
    } catch (error) {
      if (error === "Error: Request failed with status code 404") {
        console.log("No existe el usuario");
      }
    }
  };

  useEffect(() => {
    if (retoView === false) {
      setTimeout(() => {
        setImg("");
        setReto({} as Reto);
      }, 500);
    } else {
      getReto(retoViewID);
    }
  }, [retoView]);

  return (
    <>
      {retoView ? (
        <div className={`${retoView ? "bg-drawer" : "hidden"}`}>
          <div className="grow"></div>
          <div
            className={`h-screen bg-white p-2 w-screen md:w-1/3 md:min-w-fit min-w-full px-6 overflow-auto ${
              retoView ? "animate-slide-in" : "animate-slide-out"
            }`}
          >
            <div className="w-full sticky top-0 bg-white">
              <div className="w-full flex items-center my-6">
                <p className="text-lg">
                  <strong>{reto?.reto_name}</strong>
                </p>
                <div className="grow"></div>
                <button
                  onClick={() => {
                    setRetoView(!retoView);
                  }}
                >
                  <Close />
                </button>
              </div>
            </div>

            <div className="flex flex-col gap-4">
              <div className="border-2 border-slate-200 p-4 flex flex-col gap-3 rounded-xl shadow-md">
                <img
                  src={img}
                  alt=""
                  className="h-36 object-contain w-32 m-auto"
                />
                <div>
                  <strong>Descripción</strong>{" "}
                </div>
                <div>{reto?.reto_descripcion}</div>
              </div>
              <MyRetoProgressBar
                endPoint={reto?.reto_cantidad || 0}
                currentPoint={reto?.cantidad_vendida || 0}
                nombre={reto?.producto || ""}
                puntos={reto?.reto_puntos || 100}
              />
              <p className="text-lg">
                <strong>Agregar Comprobante</strong>
              </p>
              <New
                producto_id={reto?.producto_id || 0}
                reto_id={reto?.reto_id || 0}
                usuario_reto_id={reto?.usuario_reto_id || 0}
              />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default MyRetoView;
