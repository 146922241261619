import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { validateToken } from "../../auth/validateToken";

const PasswordReset = () => {
  const [password, setPassword] = useState("");
  const { token, email } = useParams();
  const navigate = useNavigate();

  const handleSubmit = async (event: any) => {
    try {
      event.preventDefault();
      Swal.fire({
        title: "Procesando...",
        text: "No cierre esta ventana.",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/reset-password`,
        { password, email },
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": token,
          },
        }
      );
      Swal.fire({
        icon: "success",
        title: "Contraseña cambiada",
        text: "Regresar a la página de inicio",
        showCancelButton: false,
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/landing");
        }
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ha ocurrido un error, por favor intenta de nuevo",
      });
      console.log(error);
    }
  };

  const validationToken = async () => {
    const response = await validateToken(token!);
    if (!response) {
      navigate("/");
    }
  };

  useEffect(() => {
    validationToken();
  }, []);

  return (
    <div className="h-screen flex justify-center items-center">
      <div className="w-1/2 h-full bg-[#dcdcdc] hidden lg:block"></div>
      <div className="w-full lg:w-1/2 h-full bg-[#b7b7b7] flex items-center justify-center">
        <div className="max-w-md w-full">
          <form
            action=""
            className="flex flex-col gap-4 mb-5"
            onSubmit={handleSubmit}
          >
            {/* Nombre */}
            <div className="block">
              <label htmlFor="" className="">
                Nueva contraseña
              </label>
              <input
                type="password"
                id="password"
                name="password"
                onChange={(e) => setPassword(e.target.value)}
                className="main-input"
                required
              />
            </div>

            {/* Save Button */}
            <button className={`cefa-button`} type="submit">
              Cambiar contraseña
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
