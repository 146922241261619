import AWS from "aws-sdk";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import ImageUploader from "../../misc/ImageUploader";
import Close from "../../../../../icons/close";
import Swal from "sweetalert2";
import Save from "../../../../../icons/save";
import StatusToggle from "../../../../../icons/toggle";
import Trash from "../../../../../icons/trash";
import Assignment from "./Assignment";
import { formatToDate } from "../../../../../utils/dates";
import ImageZoomModal from "../../misc/ImageWithZoom";
import Edit from "../../../../../icons/edit";

const s3 = new AWS.S3({
  accessKeyId: `${process.env.REACT_APP_AWS_KEYID}`,
  secretAccessKey: `${process.env.REACT_APP_AWS_ACCESSKEY}`,
});

s3.config.update({ signatureVersion: "v4" });

interface Reto {
  reto_id: number;
  nombre: string;
  descripcion: string;
  producto_id: number;
  producto_name: string;
  cantidad: number;
  puntos: number;
  fecha_inicio: string;
  fecha_fin: string;
  estado: string;
  imagen: string;
}

interface producto {
  producto_id: number;
  nombre: string;
}

interface Props {
  retoView: boolean;
  setRetoView: React.Dispatch<React.SetStateAction<boolean>>;
  retoViewID: number;
  setCounter: React.Dispatch<React.SetStateAction<number>>;
}

const RetoView = ({ retoViewID, retoView, setRetoView, setCounter }: Props) => {
  const [productos, setProductos] = useState<producto[]>([]);
  const [retoAssignmentView, setRetoAssignmentView] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [reto, setReto] = useState<Reto>({} as Reto);
  const [img, setImg] = useState("");
  const formRef = useRef<HTMLFormElement>(null);

  const handleProductoChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const selected_producto = event.target.value;
    const selected_producto_id = productos.find(
      (producto) => producto.nombre === selected_producto
    );
    if (selected_producto_id) {
      setReto({ ...reto, producto_id: selected_producto_id.producto_id });
    }
    console.log(reto);
  };

  const getReto = async (retoViewID: any) => {
    try {
      setImg("");
      setReto({} as Reto);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/retos/${retoViewID}`,
        {
          headers: {
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );

      setReto(response.data);

      const paramsS3 = {
        Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
        Key: `${response.data.imagen}`,
        Expires: 60 * 60,
      };
      s3.getSignedUrl("getObject", paramsS3, (err, url) => {
        if (err) {
          console.error(err);
        } else {
          setImg(url);
        }
      });
    } catch (error) {
      if (error === "Error: Request failed with status code 404") {
        console.log("No existe el usuario");
      }
    }
  };

  const getProductos = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/productos/active`,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );
      setProductos(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteReto = async () => {
    try {
      Swal.fire({
        title: "Procesando...",
        text: "No cierre esta ventana.",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/retos/${reto.reto_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );
      setRetoView(!retoView);
      setCounter((counter) => counter + 1);
      Swal.fire({
        icon: "success",
        title: "Reto eliminado",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Error al eliminar el reto",
      });
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      Swal.fire({
        title: "Procesando...",
        text: "No cierre esta ventana.",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      let tempFechaInicio = new Date(reto.fecha_inicio!);
      let tempFechaFin = new Date(reto.fecha_fin!);
      let data = {};
      if (selectedFile) {
        data = {
          nombre: reto.nombre,
          descripcion: reto.descripcion,
          producto_id: reto.producto_id,
          cantidad: reto.cantidad,
          puntos: reto.puntos,
          fecha_inicio: tempFechaInicio,
          fecha_fin: tempFechaFin,
          imagen: `retos/${selectedFile?.name}`,
          estado: reto.estado,
        };
      } else {
        data = {
          nombre: reto.nombre,
          descripcion: reto.descripcion,
          producto_id: reto.producto_id,
          cantidad: reto.cantidad,
          puntos: reto.puntos,
          fecha_inicio: tempFechaInicio,
          fecha_fin: tempFechaFin,
          estado: reto.estado,
        };
      }
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/retos/${reto.reto_id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (selectedFile) {
        const uploadParams = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `retos/${selectedFile?.name}`,
          Body: selectedFile,
        };
        await s3.upload(uploadParams).promise();
      } else {
      }
      setRetoView(!retoView);
      setCounter((counter) => counter + 1);
      Swal.fire({
        icon: "success",
        title: "Reto actualizado",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Error al guardar el reto",
      });
      console.error(error);
    }
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setReto({ ...reto, [name]: value });
  };

  const handleSubmitButton = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true })
      );
    }
  };

  const setStatus = async () => {
    try {
      let tempState = reto.estado === "Activo" ? "Inactivo" : "Activo";
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/retos/state/${reto.reto_id}`,
        { estado: tempState },
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );
      setReto({ ...reto, estado: tempState });
      setCounter((counter) => counter + 1);
      setRetoView(!retoView);
      Swal.fire({
        icon: "success",
        title: "¡Éxito!",
        text: "El reto ha sido actualizado con éxito.",
        confirmButtonText: "Aceptar",
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ha ocurrido un error al actualizar el reto.",
        confirmButtonText: "Aceptar",
      });
    }
  };

  useEffect(() => {
    if (retoView === false) {
      setTimeout(() => {
        setImg("");
        setReto({} as Reto);
      }, 500);
    } else {
      getReto(retoViewID);
      getProductos();
    }
  }, [retoView]);

  return (
    <>
      {retoView ? (
        <div className={`${retoView ? "bg-drawer" : "hidden"}`}>
          <div className="grow"></div>
          <div
            className={`h-full bg-white w-screen md:w-1/3 md:min-w-fit min-w-full px-6 pb-4 overflow-auto ${
              retoView ? "animate-slide-in" : "animate-slide-out"
            }`}
          >
            <div className="w-full sticky top-0 bg-white pb-4">
              <div className="w-full flex items-center my-6">
                <p className="text-lg">
                  <strong>{reto.nombre}</strong>
                </p>

                <div className="grow"></div>
                <button
                  onClick={() => {
                    setRetoView(!retoView);
                  }}
                >
                  <Close />
                </button>
              </div>
              <div className="flex-1 overflow-auto w-full ">
                <div className="flex gap-2 items-center">
                  <button
                    onClick={() => {
                      Swal.fire({
                        title: "¿Estás seguro?",
                        text: "No podrás revertir esta acción",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Sí, eliminar",
                        cancelButtonText: "Cancelar",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          deleteReto();
                        }
                      });
                    }}
                    type="button"
                  >
                    <Trash size={24} />
                  </button>
                  <button onClick={handleSubmitButton}>
                    <Save size={36} />
                  </button>
                  <div className="grow"></div>
                  <StatusToggle status={reto.estado} setStatus={setStatus} />
                  <div
                    className={`${reto.estado === "Activo" ? "" : "hidden"}`}
                  >
                    <button
                      className="cefa-button flex gap-2"
                      onClick={() => {
                        setRetoAssignmentView(!retoAssignmentView);
                      }}
                    >
                      <p>Asignar Reto</p>
                      <Edit />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <form
              ref={formRef}
              action=""
              className="flex flex-col gap-2"
              onSubmit={handleSubmit}
            >
              <div className="m-auto">
                <ImageZoomModal imageUrl={img} />
              </div>
              <div>
                <ImageUploader
                  setSelectedFile={setSelectedFile}
                  inputId="RetoView"
                />
              </div>
              <div>
                <label>Nombre*</label>
                <input
                  type="text"
                  id="nombre"
                  name="nombre"
                  value={reto.nombre}
                  onChange={handleInputChange}
                  className="main-input"
                />
              </div>
              <div>
                <label>Descripcion</label>
                <textarea
                  id="descripcion"
                  name="descripcion"
                  value={reto.descripcion}
                  onChange={handleInputChange}
                  className="main-input h-24 p-2"
                />
              </div>
              <div>
                <label>Medicamento*</label>
                <input
                  type="text"
                  id="producto_id"
                  name="producto_id"
                  defaultValue={reto.producto_name}
                  onChange={handleProductoChange}
                  list="names"
                  className="main-input"
                />
                <datalist id="names">
                  {productos.map((producto) => (
                    <option
                      key={`${producto.producto_id}`}
                      value={`${producto.nombre}`}
                    />
                  ))}
                </datalist>
              </div>
              <div>
                <label>Cantidad A Vender*</label>
                <input
                  type="number"
                  id="cantidad"
                  name="cantidad"
                  value={reto.cantidad}
                  onChange={handleInputChange}
                  className="main-input"
                />
              </div>
              <div className="flex gap-4">
                <div className="grow">
                  <label>Fecha Inicio*</label>
                  <input
                    type="date"
                    id="fecha_inicio"
                    name="fecha_inicio"
                    value={formatToDate(reto.fecha_inicio)}
                    onChange={handleInputChange}
                    className="main-input"
                  />
                </div>
                <div className="grow">
                  <label>Fecha Fin*</label>
                  <input
                    type="date"
                    id="fecha_fin"
                    name="fecha_fin"
                    value={formatToDate(reto.fecha_fin)}
                    onChange={handleInputChange}
                    className="main-input"
                  />
                </div>
              </div>
              <div>
                <label>Puntos*</label>
                <input
                  type="number"
                  id="puntos"
                  name="puntos"
                  value={reto.puntos}
                  onChange={handleInputChange}
                  className="main-input"
                />
              </div>
            </form>
          </div>
          <Assignment
            setRetoAssignmentView={setRetoAssignmentView}
            retoAssignmentView={retoAssignmentView}
            retoViewID={retoViewID}
          />
        </div>
      ) : null}
    </>
  );
};

export default RetoView;
