import AWS from "aws-sdk";
import axios from "axios";
import { useEffect, useState } from "react";
import Close from "../../../../../icons/close";
import { formatToMMDDYYYY } from "../../../../../utils/dates";
import ImageZoomModal from "../../misc/ImageWithZoom";
import ApproveIcon from "../../../../../icons/approved";
import Reject from "../../../../../icons/reject";
import Swal from "sweetalert2";

interface RetoComprobante {
  reto_comprobante_id: number;
  usuario_reto_id: number;
  imagen: string;
  cantidad: number;
  numero_factura: string;
  fecha_creacion: string;
  estado: string;
  reto_name: string;
  reto_product_id: number;
  usuario_farmacia_id: number;
  usuario_id: number;
  usuario_name: string;
  usuario_email: string;
}

interface Props {
  retoComprobanteView: boolean;
  setRetoComprobanteView: React.Dispatch<React.SetStateAction<boolean>>;
  setRetoComprobanteViewID: React.Dispatch<React.SetStateAction<number>>;
  retoComprobanteViewID: number;
  counter: number;
  setCounter: React.Dispatch<React.SetStateAction<number>>;
}

const s3 = new AWS.S3({
  accessKeyId: `${process.env.REACT_APP_AWS_KEYID}`,
  secretAccessKey: `${process.env.REACT_APP_AWS_ACCESSKEY}`,
});
s3.config.update({ signatureVersion: "v4" });

const AdminComprobanteView = ({
  retoComprobanteView,
  setRetoComprobanteView,
  retoComprobanteViewID,
  setRetoComprobanteViewID,
  counter,
  setCounter,
}: Props) => {
  const [retoComprobante, setRetoComprobante] = useState<RetoComprobante>();
  const [img, setImg] = useState("");

  const approveComprobante = async () => {
    try {
      const data = {
        usuario_reto_id: retoComprobante?.usuario_reto_id,
        usuario_correo: retoComprobante?.usuario_email,
        cantidad_vendida_to_add: retoComprobante?.cantidad,
        usuario_id: retoComprobante?.usuario_id,
        farmacia_id: retoComprobante?.usuario_farmacia_id,
        producto_id: retoComprobante?.reto_product_id,
      };

      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/reto_comprobantes/approve/${retoComprobanteViewID}`,
        data,
        {
          headers: {
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/reto_comprobantes/nextComprobante`,
        {
          headers: {
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );
      setRetoComprobanteViewID(result.data.reto_comprobante_id);
      setCounter(counter + 1);
      Swal.fire({
        icon: "success",
        title: "Comprobante aprobado",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error: any) {
      setCounter(counter + 1);
      setRetoComprobanteView(!retoComprobanteView);

      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response?.data?.message || "Something went wrong!",
      });
      console.log(error);
    }
  };

  const rejectComprobante = async () => {
    try {
      Swal.fire({
        title: "Procesando...",
        text: "No cierre esta ventana.",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      const data = {
        usuario_correo: retoComprobante?.usuario_email,
      };
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/reto_comprobantes/reject/${retoComprobanteViewID}`,
        data,
        {
          headers: {
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/reto_comprobantes/nextComprobante`,
        {
          headers: {
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );
      setRetoComprobanteViewID(result.data.reto_comprobante_id);
      setCounter(counter + 1);

      Swal.fire({
        icon: "success",
        title: "Comprobante rechazado",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error: any) {
      setCounter(counter + 1);
      setRetoComprobanteView(!retoComprobanteView);

      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response?.data?.message || "Something went wrong!",
      });
      console.log(error);
    }
  };

  const getComprobante = async (retoComprobanteViewID: any) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/reto_comprobantes/${retoComprobanteViewID}`,
        {
          headers: {
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );
      setRetoComprobante(response.data);

      const paramsS3 = {
        Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
        Key: `comprobantes/${response.data.imagen}`,
        Expires: 60 * 60,
      };

      s3.getSignedUrl("getObject", paramsS3, (err, url) => {
        if (err) {
          console.error(err);
        } else {
          setImg(url);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (retoComprobanteViewID !== 0) {
      getComprobante(retoComprobanteViewID);
    }
  }, [retoComprobanteViewID]);

  return (
    <>
      {retoComprobanteView ? (
        <div className={`${retoComprobanteView ? "bg-drawer" : "hidden"}`}>
          <div className="grow"></div>
          <div
            className={`h-full bg-white p-2 w-screen md:w-1/3 md:min-w-fit min-w-full px-6 ${
              retoComprobanteView ? "animate-slide-in" : "animate-slide-out"
            }`}
          >
            <div className="w-full sticky top-0 bg-white">
              <div className="w-full flex items-center mt-4">
                <p className="text-right mr-4 text-lg flex flex-row">
                  <strong>Comprobante</strong>
                </p>
                <div className="grow" />
                <button
                  onClick={() => {
                    setRetoComprobanteView(!retoComprobanteView);
                  }}
                >
                  <Close />
                </button>
              </div>
            </div>
            <div className={`flex flex-col`}>
              <div className="grow w-full flex flex-col gap-3 ">
                <div className="flex flex-col gap-3">
                  <ImageZoomModal imageUrl={img} />
                  <div className="flex flex-col gap-2">
                    <div className="flex gap-1">
                      <p>
                        <strong>Nombre del reto:</strong>
                      </p>
                      <div>{retoComprobante?.reto_name}</div>
                    </div>
                    <div className="flex gap-1">
                      <p>
                        <strong>Numero de factura:</strong>
                      </p>
                      <div>{retoComprobante?.numero_factura}</div>
                    </div>
                    <div className="flex gap-1">
                      <p>
                        <strong>Cantidad vendida:</strong>
                      </p>
                      <div>{retoComprobante?.cantidad}</div>
                    </div>
                    <div className="flex gap-1">
                      <p>
                        <strong>Fecha de creacion:</strong>
                      </p>
                      <div>
                        {formatToMMDDYYYY(retoComprobante?.fecha_creacion)}
                      </div>
                    </div>
                    <div className="flex gap-1">
                      <p>
                        <strong>Estado:</strong>
                      </p>
                      <div>{retoComprobante?.estado}</div>
                    </div>
                    <div className="text-lg">
                      <strong>Informacion Del Colaborador</strong>
                    </div>
                    <div className="flex gap-1">
                      <p>
                        <strong>Nombre:</strong>
                      </p>
                      <div>{retoComprobante?.usuario_name}</div>
                    </div>
                  </div>
                  <div className="flex flex-row gap-2">
                    {retoComprobante?.estado === "Aprobado" ||
                    retoComprobante?.estado === "Rechazado" ? null : (
                      <>
                        <button
                          onClick={approveComprobante}
                          type="button"
                          className="cefa-button"
                        >
                          <ApproveIcon />
                        </button>
                        <button
                          onClick={rejectComprobante}
                          type="button"
                          className="reject-button"
                        >
                          <Reject />
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default AdminComprobanteView;
