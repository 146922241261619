interface Props {
  size: number;
}

const trash = ({ size }: Props) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5 7.25V6.55C15.5 5.56991 15.5 5.07986 15.3093 4.70552C15.1415 4.37623 14.8738 4.10852 14.5445 3.94074C14.1701 3.75 13.6801 3.75 12.7 3.75H11.3C10.3199 3.75 9.82986 3.75 9.45552 3.94074C9.12623 4.10852 8.85852 4.37623 8.69074 4.70552C8.5 5.07986 8.5 5.56991 8.5 6.55V7.25M10.25 12.0625V16.4375M13.75 12.0625V16.4375M4.125 7.25H19.875M18.125 7.25V17.05C18.125 18.5201 18.125 19.2552 17.8389 19.8167C17.5872 20.3106 17.1857 20.7122 16.6917 20.9639C16.1302 21.25 15.3951 21.25 13.925 21.25H10.075C8.60486 21.25 7.86979 21.25 7.30828 20.9639C6.81435 20.7122 6.41278 20.3106 6.16111 19.8167C5.875 19.2552 5.875 18.5201 5.875 17.05V7.25"
        stroke="#667085"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default trash;
