const cefamoneda = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 23C18.5228 23 23 18.5228 23 13C23 7.47715 18.5228 3 13 3C7.47715 3 3 7.47715 3 13C3 18.5228 7.47715 23 13 23Z"
        fill="#FFC700"
      />
      <path
        d="M16.8333 9.27316C15.9487 8.48142 14.7806 8 13.5 8C10.7386 8 8.5 10.2386 8.5 13C8.5 15.7614 10.7386 18 13.5 18C14.7806 18 15.9487 17.5186 16.8333 16.7268M13 19.5V6.5M23 13C23 18.5228 18.5228 23 13 23C7.47715 23 3 18.5228 3 13C3 7.47715 7.47715 3 13 3C18.5228 3 23 7.47715 23 13Z"
        stroke="#A97A00"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default cefamoneda;
