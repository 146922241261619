import Menu from "./Menu";
import { useEffect, useState } from "react";
import Premios from "./premio/Premio";
import Retos from "./reto/Reto";
import Pedidos from "./pedidos/Pedidos";
import Usuario from "./usuarios/Usuarios";
import Farmacias from "./farmacias/Farmacias";
import VentaComprobante from "./venta_comprobantes/List";
import RetoComprobante from "./reto_comprobantes/Comprobantes";
import { useNavigate } from "react-router-dom";
import Productos from "./productos/Productos";
import Publicidad from "./publicidad/Publicidad";
import Reports from "./reports/Reports";
import Profile from "./perfil/Profile";
import { validateToken } from "../../../../auth/validateToken";

function StatusContent({ status }: any) {
  switch (status) {
    case "Premios":
      return <Premios />;
    case "Retos":
      return <Retos />;
    case "Pedidos":
      return <Pedidos />;
    case "Usuarios":
      return <Usuario />;
    case "Farmacias":
      return <Farmacias />;
    case "RetoComprobantes":
      return <RetoComprobante />;
    case "VentaComprobantes":
      return <VentaComprobante />;
    case "Productos":
      return <Productos />;
    case "Publicidad":
      return <Publicidad />;
    case "Reportes":
      return <Reports />;
    case "Perfil":
      return <Profile />;
    default:
      return <div>Unknown status.</div>;
  }
}

const AdminHome = () => {
  const [view, setView] = useState("Premios");
  const navigate = useNavigate();

  useEffect(() => {
    if (sessionStorage.getItem("rol") === "Admin") {
      const token = sessionStorage.getItem("token");
      if (token) {
        validateToken(token).then((isValid) => {
          if (!isValid) {
            sessionStorage.clear();
            navigate("/");
          }
        });
      } else {
        navigate("/");
      }
    } else {
      navigate("/");
    }
  }, [navigate]);

  return (
    <div className="flex flex-col md:flex-row grow overflow-hidden h-screen w-screen">
      <Menu view={view} setView={setView} />
      <div className="p-4 overflow-hidden w-full">
        <StatusContent status={view} />
      </div>
    </div>
  );
};

export default AdminHome;
