import React, { useRef, useState } from "react";
import axios from "axios";
import ImageUploader from "../../misc/ImageUploader";
import AWS from "aws-sdk";
import Save from "../../../../../icons/save";
import Swal from "sweetalert2";

interface Props {
  nombre: string;
  apellido: string;
  email: string;
  pais: string;
  canton: string;
  distrito: string;
  barrio: string;
  direccion: string;
}

const s3 = new AWS.S3({
  accessKeyId: `${process.env.REACT_APP_AWS_KEYID}`,
  secretAccessKey: `${process.env.REACT_APP_AWS_ACCESSKEY}`,
});
s3.config.update({ signatureVersion: "v4" });

const ProfileForm = ({
  nombre,
  apellido,
  email,
  pais,
  canton,
  distrito,
  barrio,
  direccion,
}: Props) => {
  const [selectedFile, setSelectedFile] = useState<File>();

  const nombreDataRef = useRef<HTMLInputElement>(null);
  const apellidoDataRef = useRef<HTMLInputElement>(null);

  const [usuario, setUsuario] = useState({
    nombre: undefined,
    apellido: undefined,
    pais: undefined,
    canton: undefined,
    distrito: undefined,
    barrio: undefined,
    direccion: undefined,
  });

  const updateProfile = async (event: React.FormEvent) => {
    try {
      event.preventDefault();
      let data;

      const nameData = nombreDataRef.current?.value ?? "";
      const apellidoData = apellidoDataRef.current?.value ?? "";

      if (selectedFile !== undefined) {
        data = {
          nombre: usuario.nombre,
          apellido: usuario.apellido,
          pais: usuario.pais,
          canton: usuario.canton,
          distrito: usuario.distrito,
          barrio: usuario.barrio,
          direccion: usuario.direccion,
          foto: `usuarios/${nameData}-${apellidoData}-${selectedFile?.name}`,
        };
      } else {
        data = {
          nombre: usuario.nombre,
          apellido: usuario.apellido,
          pais: usuario.pais,
          canton: usuario.canton,
          distrito: usuario.distrito,
          barrio: usuario.barrio,
          direccion: usuario.direccion,
        };
      }

      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/usuarios/${sessionStorage.getItem(
          "user_id"
        )}`,
        data,
        {
          headers: {
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );
      if (selectedFile !== undefined) {
        const uploadParams = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `usuarios/${nameData}-${apellidoData}-${selectedFile?.name}`,
          Body: selectedFile,
        };

        try {
          await s3.upload(uploadParams).promise();
        } catch (error) {
          console.error("Error uploading file:", error);
        }
      }
      Swal.fire({
        icon: "success",
        title: "Perfil actualizado",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error al actualizar el perfil",
        showConfirmButton: false,
        timer: 1500,
      });
      console.error(error);
    }
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setUsuario({ ...usuario, [name]: value });
  };

  return (
    <div className="overflow-x-scroll max-w-lg m-auto w-full p-2">
      <form action="" className="w-full grid gap-2" onSubmit={updateProfile}>
        <button>
          <Save size={45} />
        </button>
        <div>
          <ImageUploader
            setSelectedFile={setSelectedFile}
            inputId="UsuarioPerfil"
          />
        </div>
        <div className="block">
          <label htmlFor="" className="">
            Nombre
          </label>
          <input
            type="text"
            id="nombre"
            name="nombre"
            defaultValue={nombre}
            onChange={handleInputChange}
            className="main-input"
          />
        </div>
        <div className="block">
          <label htmlFor="" className="">
            Apellido
          </label>
          <input
            type="text"
            id="apellido"
            name="apellido"
            defaultValue={apellido}
            onChange={handleInputChange}
            className="main-input"
          />
        </div>
        <div className="block">
          <label htmlFor="" className="">
            Correo Electronico
          </label>
          <input
            readOnly={true}
            type="text"
            defaultValue={email}
            id="email"
            name="email"
            onChange={handleInputChange}
            className="main-input"
          />
        </div>
        <p>Direccion</p>
        <div className="block">
          <label htmlFor="" className="">
            Pais
          </label>
          <input
            type="text"
            defaultValue={pais}
            id="pais"
            name="pais"
            onChange={handleInputChange}
            className="main-input"
          />
        </div>
        <div className="block">
          <label htmlFor="" className="">
            Canton
          </label>
          <input
            type="text"
            defaultValue={canton}
            id="canton"
            name="canton"
            onChange={handleInputChange}
            className="main-input"
          />
        </div>
        <div className="block">
          <label htmlFor="" className="">
            Distrito
          </label>
          <input
            type="text"
            defaultValue={distrito}
            id="distrito"
            name="distrito"
            onChange={handleInputChange}
            className="main-input"
          />
        </div>
        <div className="block">
          <label htmlFor="" className="">
            Barrio
          </label>
          <input
            type="text"
            defaultValue={barrio}
            id="barrio"
            name="barrio"
            onChange={handleInputChange}
            className="main-input"
          />
        </div>
        <div className="block">
          <label htmlFor="" className="">
            Direccion
          </label>
          <input
            type="text"
            defaultValue={direccion}
            id="direccion"
            name="direccion"
            onChange={handleInputChange}
            className="main-input"
          />
        </div>
      </form>
    </div>
  );
};

export default ProfileForm;
