import React, { useEffect, useState } from "react";

interface Props {
  setSelectedFile: (file: File | undefined) => void;
  selectedFileView?: boolean;
  inputId: string;
}

const VideoUploader = ({
  setSelectedFile,
  selectedFileView,
  inputId,
}: Props) => {
  const [preview, setPreview] = useState<string | undefined>(undefined);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onload = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedFile(undefined);
      setPreview(undefined);
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files && event.dataTransfer.files[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onload = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  useEffect(() => {
    setPreview(undefined);
  }, [selectedFileView]);

  return (
    <>
      <div
        className="border border-gray-300 rounded-lg shadow-md flex flex-col items-center cursor-pointer"
        onClick={() => document.getElementById(inputId)?.click()}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        {preview ? (
          <video src={preview} className="h-48 w-64 m-auto" controls>
            Your browser does not support the video tag.
          </video>
        ) : (
          <p className="p-4">Drag your video here or click to select a video</p>
        )}
        <input
          type="file"
          id={inputId}
          name="fileInput"
          className="hidden"
          accept="video/mp4, video/webm"
          onChange={handleFileChange}
        />
      </div>
    </>
  );
};

export default VideoUploader;
