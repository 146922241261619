import axios from "axios";
import { useEffect, useState } from "react";
import View from "./View";
import ViewIcon from "../../../../../icons/view";

interface pedido {
  pedido_id: number;
  fecha_creacion: string;
  estado: string;
}

const PedidosList = () => {
  const [pedidos, setPedidos] = useState<pedido[]>([]);
  const [pedidoView, setPedidoView] = useState(false);
  const [pedidoViewID, setPedidoViewID] = useState<number>(0);

  const getPedidos = async () => {
    const response = await axios.get(
      `${
        process.env.REACT_APP_API_URL
      }/api/pedidos/usuario/${sessionStorage.getItem("user_id")}`,
      {
        headers: {
          "auth-token": `${sessionStorage.getItem("token")}`,
        },
      }
    );
    setPedidos(response.data);
  };
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${day}/${month}/${year}`;
  };
  const [estadoFilter, setEstadoFilter] = useState("ambos");

  const filteredPedidos = pedidos.filter((pedido) => {
    if (
      (estadoFilter === "Pendiente" && pedido.estado === "Pendiente") ||
      (estadoFilter === "En camino" && pedido.estado === "En camino") ||
      estadoFilter === "ambos"
    ) {
      return true;
    }
    return false;
  });

  useEffect(() => {
    getPedidos();
  }, []);

  return (
    <div className="flex flex-col gap-2 ">
      <p className="text-xl">
        <strong>Pedidos</strong>
      </p>
      <select
        value={estadoFilter}
        onChange={(e) => setEstadoFilter(e.target.value)}
        className="main-dropdown"
      >
        <option value="ambos">Todos</option>
        <option value="Pendiente">Pendiente</option>
        <option value="En camino">En camino</option>
      </select>
      <div
        className="overflow-auto bg-white pb-8 md:pb-0"
        style={{ maxHeight: "calc(70vh)" }}
      >
        <table className="w-full">
          <thead className="bg-gray-50 top-0 sticky">
            <tr className="table-head text-left">
              <th>Fecha del pedido</th>
              <th>Estado</th>
              <th className="text-center">Acciones</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {pedidos.length === 0 ? (
              <tr className="hover:bg-slate-300">
                <td className="text-center" colSpan={6}>
                  No hay premios disponibles
                </td>
              </tr>
            ) : (
              filteredPedidos.map((pedido) => (
                <tr
                  className="hover:bg-slate-100 table-body"
                  key={pedido.pedido_id}
                >
                  <td>{formatDate(pedido.fecha_creacion)}</td>
                  <td>{pedido.estado}</td>
                  <td>
                    <div className="w-fit m-auto">
                      <button
                        onClick={() => {
                          setPedidoView(!pedidoView);
                          setPedidoViewID(pedido.pedido_id);
                        }}
                      >
                        <ViewIcon />
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <View
        pedidoView={pedidoView}
        setPedidoView={setPedidoView}
        pedidoViewID={pedidoViewID}
      />
    </div>
  );
};

export default PedidosList;
