import React from "react";

const ayuda = () => {
  return (
    <div>
      <h1>¿Necesitas ayuda?</h1>
      <p>Contáctanos a través de:</p>
      <a
        className="cefa-button"
        href="https://wa.me/+50672023442"
        target="_blank"
        rel="noopener noreferrer"
      >
        Whatsapp
      </a>
      <p>Correo Electronico </p>
      <p>oscar@3v0luci0n.com</p>
    </div>
  );
};

export default ayuda;
