import axios from "axios";
import { useEffect, useState } from "react";
import PremiosList from "./Items";
import Close from "../../../../../icons/close";
import { formatToDate } from "../../../../../utils/dates";

interface Pedido {
  pedido_id: number;
  estado: string;
  fecha_creacion: string;
}

interface Props {
  pedidoView: boolean;
  setPedidoView: React.Dispatch<React.SetStateAction<boolean>>;
  pedidoViewID: number;
}
const PedidoView = ({ pedidoView, setPedidoView, pedidoViewID }: Props) => {
  const [isClosing, setIsClosing] = useState(false);
  const [pedido, setPedido] = useState<Pedido>();

  const getPedido = async (pedidoViewID: any) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/pedidos/${pedidoViewID}`,
      {
        headers: {
          "auth-token": `${sessionStorage.getItem("token")}`,
        },
      }
    );
    setPedido(response.data);
  };

  useEffect(() => {
    getPedido(pedidoViewID);
  }, [pedidoView]);

  return (
    <>
      {pedidoView || isClosing ? (
        <div className={`${pedidoView ? "bg-drawer" : "hidden"}`}>
          <div className="grow"></div>
          <div
            className={`h-full bg-white p-2 w-screen md:w-1/3 md:min-w-fit min-w-full px-6 ${
              isClosing ? "animate-slide-out" : "animate-slide-in"
            }`}
          >
            <div className="w-full sticky top-0 bg-white">
              <div className="w-full flex items-center mt-4">
                <div className="grow"></div>
                <button
                  onClick={() => {
                    if (pedidoView) {
                      setIsClosing(true);
                      setTimeout(() => {
                        setPedidoView(false);
                        setIsClosing(false);
                      }, 1000);
                    } else {
                      setPedidoView(true);
                    }
                  }}
                >
                  <Close />
                </button>
              </div>
            </div>

            <div className="grow w-full p-2 flex flex-col gap-6">
              {/* Fecha Inicio */}
              <div className="block">
                <label htmlFor="" className="">
                  Fecha Inicio
                </label>
                <input
                  readOnly={true}
                  type="date"
                  id="fecha_inicio"
                  name="fecha_inicio"
                  value={formatToDate(pedido?.fecha_creacion)}
                  className="main-input"
                />
              </div>
              <div>
                <label htmlFor="" className="">
                  Estado
                </label>
                <input
                  readOnly={true}
                  type="text"
                  id="puntos"
                  name="puntos"
                  defaultValue={pedido?.estado}
                  className="main-input"
                />
              </div>
              <div>
                <strong>Articulos solicitados</strong>
                <PremiosList pedidoViewID={pedidoViewID} />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default PedidoView;
