import AWS from "aws-sdk";
import { useEffect, useState } from "react";
import Profile from "../../misc/ProfilePicture";
import Cefamoneda from "../../../../../icons/cefamoneda";

//Props
interface Props {
  foto: string;
  puntos: number | any;
  retos: number;
  name: string;
}

//Configuration of AWS S3
const s3 = new AWS.S3({
  accessKeyId: `${process.env.REACT_APP_AWS_KEYID}`,
  secretAccessKey: `${process.env.REACT_APP_AWS_ACCESSKEY}`,
});
s3.config.update({ signatureVersion: "v4" });

const UserCard = ({ foto, puntos, retos, name }: Props) => {
  const [image, setImage] = useState("");

  const getImage = async () => {
    const paramsS3 = {
      Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
      Key: `${foto}`,
      Expires: 60 * 60,
    };
    s3.getSignedUrl("getObject", paramsS3, (err, url) => {
      if (err) {
        console.error(err);
      } else {
        setImage(url);
      }
    });
  };

  const formatPuntos = () => {
    if (puntos !== undefined) {
      const formatted = new Intl.NumberFormat("en-US", {
        style: "decimal",
        minimumFractionDigits: 2,
      }).format(parseFloat(puntos));
      return formatted;
    }
    return "";
  };

  useEffect(() => {
    getImage();
  }, [foto, name]);

  return (
    <div className="w-full h-full rounded flex items-center justify-center gap-5 px-5 py-3 border border-slate-300">
      <Profile image={image} name={name} />
      <div className="grow">
        <div className="flex items-end justify-end gap-2">
          <div className="text-4xl text-right">{formatPuntos()}</div>
          <Cefamoneda />
        </div>
        <p className="text-s text-right">{retos} Retos Completados</p>
      </div>
    </div>
  );
};

export default UserCard;
