import React from "react";

//Props
interface Props {
  endPoint: number;
  currentPoint: number;
  nombre: string;
  puntos: number;
}

const MyRetoProgressBar: React.FC<Props> = ({
  endPoint,
  currentPoint,
  nombre,
  puntos,
}) => {
  let progress = (currentPoint / endPoint) * 100;
  if (progress > 100) {
    progress = 100;
  }

  return (
    <>
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-4">
          <div>{nombre}</div>

          <div>{puntos} puntos</div>
        </div>
        <div className="w-full bg-gray-300 rounded">
          <div
            className="h-full bg-green-500 rounded"
            style={{ width: `${Number(progress)}%` }}
          >
            <p className="block text-center pl-1">
              {`${Math.round(Number(progress))}`}%
            </p>
          </div>
        </div>
        <div>
          <p>
            Unidad vendidas: {currentPoint} de {endPoint}
          </p>
        </div>
      </div>
    </>
  );
};

export default MyRetoProgressBar;
