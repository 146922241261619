import { useEffect, useState } from "react";
import axios from "axios";
import Close from "../../../../../icons/close";
import Swal from "sweetalert2";

interface farmacia {
  nombre: string;
  direccion: string;
  telefono: string;
  email: string;
}
interface Props {
  view: boolean;
  setView: React.Dispatch<React.SetStateAction<boolean>>;
  counter: number;
  setCounter: React.Dispatch<React.SetStateAction<number>>;
}
const FarmaciaNew = ({ view, setView, setCounter, counter }: Props) => {
  const [farmacia, setFarmacia] = useState<farmacia>({
    nombre: "",
    direccion: "",
    telefono: "",
    email: "",
  });

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setFarmacia({ ...farmacia, [name]: value });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      Swal.fire({
        title: "Procesando...",
        text: "No cierre esta ventana.",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      const data = {
        nombre: farmacia.nombre,
        direccion: farmacia.direccion,
        telefono: farmacia.telefono,
        email: farmacia.email,
      };
      await axios.post(`${process.env.REACT_APP_API_URL}/api/farmacias`, data, {
        headers: {
          "Content-Type": "application/json",
          "auth-token": `${sessionStorage.getItem("token")}`,
        },
      });
      setCounter(counter + 1);
      setView(!view);
      Swal.fire({
        title: "Farmacia creada",
        icon: "success",
        confirmButtonText: "Ok",
      });
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "No se pudo crear la farmacia",
        icon: "error",
        confirmButtonText: "Ok",
      });
      console.error(error);
    }
  };

  useEffect(() => {}, [farmacia]);

  return (
    <>
      {view ? (
        <div className={`${view ? "bg-drawer" : "hidden"}`}>
          <div className="grow"></div>
          <div
            className={`h-full bg-white p-2 w-screen md:w-1/3 px-6 ${
              view ? "animate-slide-in" : "animate-slide-out"
            }`}
          >
            <div className="w-full sticky top-0 bg-white flex items-center my-4">
              <p className="text-lg">
                <strong>Nueva Farmacia</strong>
              </p>
              <div className="grow" />
              <button onClick={() => setView(!view)}>
                <Close />
              </button>
            </div>

            <form
              action=""
              className="w-full flex flex-col gap-6"
              onSubmit={handleSubmit}
            >
              <div className="block">
                <label htmlFor="" className="">
                  Nombre*
                </label>
                <input
                  type="text"
                  id="nombre"
                  name="nombre"
                  onChange={handleInputChange}
                  className="main-input"
                  required
                />
              </div>
              <div className="block">
                <label htmlFor="" className="">
                  Dirección
                </label>
                <input
                  type="text"
                  id="direccion"
                  name="direccion"
                  onChange={handleInputChange}
                  className="main-input"
                />
              </div>
              <div className="block">
                <label htmlFor="" className="">
                  Telefono
                </label>
                <input
                  type="number"
                  id="telefono"
                  name="telefono"
                  onChange={handleInputChange}
                  className="main-input"
                />
              </div>
              <div className="block ">
                <label htmlFor="" className="">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  onChange={handleInputChange}
                  className="main-input"
                />
              </div>
              <button className={`cefa-button`}>Crear Farmacia</button>
            </form>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default FarmaciaNew;
