import axios from "axios";

export const validateToken = async (token: string) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_API_URL}/api/auth/validate`,
      {},
      {
        headers: {
          "auth-token": `${token}`,
        },
      }
    );

    return true;
  } catch (error) {
    return false;
  }
};
