import ExcelExport from "./ExcelExport";

const Reports = () => {
  return (
    <>
      <div className="flex flex-col h-screen">
        <div className="flex items-center">
          <h1 className="grow text-center text-lg">
            <strong>Reportes</strong>{" "}
          </h1>
        </div>

        <div className="grow max-w-xl m-auto w-full">
          <ExcelExport />
        </div>
      </div>
    </>
  );
};

export default Reports;
