import React, { useEffect, useState } from "react";
interface Props {
  image: string;
  name: string;
}

const ProfilePicture = ({ image, name }: Props) => {
  const [imageError, setImageError] = useState(false);

  const renderFallback = () => {
    if (!name) {
      return <div className="h-24 w-24 rounded-full bg-blue-500"></div>;
    }
    const firstLetter = name.charAt(0).toUpperCase();
    return (
      <div className="h-24 w-24 rounded-full bg-blue-500 flex items-center justify-center">
        <span className="text-xl text-white">{firstLetter}</span>
      </div>
    );
  };

  useEffect(() => {
    if (image) {
      setImageError(false);
    }
  }, [image, name]);

  return (
    <div>
      {imageError || !image ? (
        renderFallback()
      ) : (
        <img
          className="h-24 w-24 rounded-full"
          src={image}
          alt={name}
          onError={() => setImageError(true)}
        />
      )}
    </div>
  );
};

export default ProfilePicture;
