import List from "./List";
import { useEffect, useState } from "react";
import Add from "../../../../../icons/add";
import New from "./New";

const Farmacias = () => {
  const [view, setView] = useState(false);
  const [counter, setCounter] = useState(0);

  useEffect(() => {}, [view]);

  return (
    <div className="flex flex-col grow overflow-clip">
      <div className="flex items-center py-4">
        <p className="font-bold grow text-2xl">
          <strong>Farmacias</strong>
        </p>
        <div>
          <button onClick={() => setView(!view)} className="cefa-button">
            <p>Agregar Farmacia</p>
            <Add />
          </button>
        </div>
      </div>
      <New
        view={view}
        setView={setView}
        setCounter={setCounter}
        counter={counter}
      />
      <div className="flex-grow overflow-hidden">
        <List counter={counter} setCounter={setCounter} />
      </div>
    </div>
  );
};

export default Farmacias;
