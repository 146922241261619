import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import ImageUploader from "../../misc/ImageUploader";
import ImageZoom from "../../misc/ImageWithZoom";
import Carousel from "../../misc/Carousel";
import Save from "../../../../../icons/save";
import axios from "axios";
import AWS from "aws-sdk";

const CarouselTwo = () => {
  const s3 = new AWS.S3({
    accessKeyId: `${process.env.REACT_APP_AWS_KEYID}`,
    secretAccessKey: `${process.env.REACT_APP_AWS_ACCESSKEY}`,
  });
  s3.config.update({ signatureVersion: "v4" });
  const [pictureOneCarouselTwo, setPictureOneCarouselTwo] = useState<File>();
  const [pictureTwoCarouselTwo, setPictureTwoCarouselTwo] = useState<File>();
  const [pictureThreeCarouselTwo, setPictureThreeCarouselTwo] =
    useState<File>();
  const [pictureFourCarouselTwo, setPictureFourCarouselTwo] = useState<File>();
  const [pictureFiveCarouselTwo, setPictureFiveCarouselTwo] = useState<File>();
  const [pictureOneCarouselTwoPreview, setPictureOneCarouselTwoPreview] =
    useState<string>("");
  const [pictureTwoCarouselTwoPreview, setPictureTwoCarouselTwoPreview] =
    useState<string>("");
  const [pictureThreeCarouselTwoPreview, setPictureThreeCarouselTwoPreview] =
    useState<string>("");
  const [pictureFourCarouselTwoPreview, setPictureFourCarouselTwoPreview] =
    useState<string>("");
  const [pictureFiveCarouselTwoPreview, setPictureFiveCarouselTwoPreview] =
    useState<string>("");

  //URl

  const [pictureOneCarouselTwoURL, setPictureOneCarouselTwoURL] =
    useState<string>("");
  const [pictureTwoCarouselTwoURL, setPictureTwoCarouselTwoURL] =
    useState<string>("");
  const [pictureThreeCarouselTwoURL, setPictureThreeCarouselTwoURL] =
    useState<string>("");
  const [pictureFourCarouselTwoURL, setPictureFourCarouselTwoURL] =
    useState<string>("");
  const [pictureFiveCarouselTwoURL, setPictureFiveCarouselTwoURL] =
    useState<string>("");

  const [carouselTwoImages, setCarouselTwoImages] = useState<any>([]);
  const handleCarouselTwo = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    Swal.fire({
      title: "Uploading...",
      text: "Please wait while we upload the images.",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      if (pictureOneCarouselTwo) {
        const uploadParams = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `publicidad/${pictureOneCarouselTwo?.name}`,
          Body: pictureOneCarouselTwo,
        };
        await s3.upload(uploadParams).promise();
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/publicidad?type=CarouselTwoPictureOne`,
          {
            image: `publicidad/${pictureOneCarouselTwo?.name}`,
            external_url: pictureOneCarouselTwoURL,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "auth-token": `${sessionStorage.getItem("token")}`,
            },
          }
        );
      }

      if (pictureTwoCarouselTwo) {
        const uploadParams = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `publicidad/${pictureTwoCarouselTwo?.name}`,
          Body: pictureTwoCarouselTwo,
        };
        await s3.upload(uploadParams).promise();
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/publicidad?type=CarouselTwoPictureTwo`,
          {
            image: `publicidad/${pictureTwoCarouselTwo?.name}`,
            external_url: pictureTwoCarouselTwoURL,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "auth-token": `${sessionStorage.getItem("token")}`,
            },
          }
        );
      }

      if (pictureThreeCarouselTwo) {
        const uploadParams = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `publicidad/${pictureThreeCarouselTwo?.name}`,
          Body: pictureThreeCarouselTwo,
        };
        await s3.upload(uploadParams).promise();
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/publicidad?type=CarouselTwoPictureThree`,
          {
            image: `publicidad/${pictureThreeCarouselTwo?.name}`,
            external_url: pictureThreeCarouselTwoURL,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "auth-token": `${sessionStorage.getItem("token")}`,
            },
          }
        );
      }

      if (pictureFourCarouselTwo) {
        const uploadParams = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `publicidad/${pictureFourCarouselTwo?.name}`,
          Body: pictureFourCarouselTwo,
        };
        await s3.upload(uploadParams).promise();
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/publicidad?type=CarouselTwoPictureFour`,
          {
            image: `publicidad/${pictureFourCarouselTwo?.name}`,
            external_url: pictureFourCarouselTwoURL,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "auth-token": `${sessionStorage.getItem("token")}`,
            },
          }
        );
      }

      if (pictureFiveCarouselTwo) {
        const uploadParams = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `publicidad/${pictureFiveCarouselTwo?.name}`,
          Body: pictureFiveCarouselTwo,
        };
        await s3.upload(uploadParams).promise();
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/publicidad?type=CarouselTwoPictureFive`,
          {
            image: `publicidad/${pictureFiveCarouselTwo?.name}`,
            external_url: pictureFiveCarouselTwoURL,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "auth-token": `${sessionStorage.getItem("token")}`,
            },
          }
        );
      }
      Swal.fire({
        icon: "success",
        title: "Images uploaded successfully",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "An error occurred during the upload.",
      });
      console.error("Error uploading file:", error);
    }
  };

  const getCarouselTwo = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/publicidad/carouselTwo`
      );

      let tempImageOne = response.data.filter(
        (image: any) => image.placement === "CarouselTwoPictureOne"
      );
      let tempImageTwo = response.data.filter(
        (image: any) => image.placement === "CarouselTwoPictureTwo"
      );

      let tempImageThree = response.data.filter(
        (image: any) => image.placement === "CarouselTwoPictureThree"
      );

      let tempImageFour = response.data.filter(
        (image: any) => image.placement === "CarouselTwoPictureFour"
      );

      let tempImageFive = response.data.filter(
        (image: any) => image.placement === "CarouselTwoPictureFive"
      );

      if (tempImageOne) {
        let paramsS3 = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `${tempImageOne[0].link}`,
          Expires: 60 * 60,
        };

        s3.getSignedUrl("getObject", paramsS3, (err, url) => {
          if (err) {
            console.error(err);
          } else {
            addImageToCarouselTwo({
              image: url,
              external_url: tempImageOne[0].external_url,
            });
            setPictureOneCarouselTwoPreview(url);
            setPictureOneCarouselTwoURL(tempImageOne[0].external_url);
          }
        });
      }

      if (tempImageTwo) {
        let paramsS3 = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `${tempImageTwo[0].link}`,
          Expires: 60 * 60,
        };
        s3.getSignedUrl("getObject", paramsS3, (err, url) => {
          if (err) {
            console.error(err);
          } else {
            addImageToCarouselTwo({
              image: url,
              external_url: tempImageTwo[0].external_url,
            });
            setPictureTwoCarouselTwoPreview(url);
            setPictureTwoCarouselTwoURL(tempImageTwo[0].external_url);
          }
        });
      }

      if (tempImageThree) {
        let paramsS3 = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `${tempImageThree[0].link}`,
          Expires: 60 * 60,
        };
        s3.getSignedUrl("getObject", paramsS3, (err, url) => {
          if (err) {
            console.error(err);
          } else {
            addImageToCarouselTwo({
              image: url,
              external_url: tempImageThree[0].external_url,
            });
            setPictureThreeCarouselTwoPreview(url);
            setPictureThreeCarouselTwoURL(tempImageThree[0].external_url);
          }
        });
      }

      if (tempImageFour) {
        let paramsS3 = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `${tempImageFour[0].link}`,
          Expires: 60 * 60,
        };
        s3.getSignedUrl("getObject", paramsS3, (err, url) => {
          if (err) {
            console.error(err);
          } else {
            addImageToCarouselTwo({
              image: url,
              external_url: tempImageFour[0].external_url,
            });
            setPictureFourCarouselTwoPreview(url);
            setPictureFourCarouselTwoURL(tempImageFour[0].external_url);
          }
        });
      }

      if (tempImageFive) {
        let paramsS3 = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `${tempImageFive[0].link}`,
          Expires: 60 * 60,
        };
        s3.getSignedUrl("getObject", paramsS3, (err, url) => {
          if (err) {
            console.error(err);
          } else {
            addImageToCarouselTwo({
              image: url,
              external_url: tempImageFive[0].external_url,
            });
            setPictureFiveCarouselTwoPreview(url);
            setPictureFiveCarouselTwoURL(tempImageFive[0].external_url);
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const addImageToCarouselTwo = (newImageUrl: any) => {
    setCarouselTwoImages((prevImages: any) => [...prevImages, newImageUrl]);
  };

  useEffect(() => {
    getCarouselTwo();
  }, []);

  return (
    <div className="p-2 border border-slate-200 rounded m-2">
      <p>Carrucel #2</p>
      <Carousel images={carouselTwoImages} />
      <form action="" className="gap-2 my-4" onSubmit={handleCarouselTwo}>
        <div className="flex">
          <h1>Seccion Carrucel #2</h1>
          <button>
            <Save size={26} />
          </button>
        </div>
        <div className="flex gap-2">
          <div className="flex flex-col gap-2">
            <p>Foto #1</p>
            <input
              value={pictureOneCarouselTwoURL}
              type="text"
              className="main-input"
            />
            <ImageUploader
              setSelectedFile={setPictureOneCarouselTwo}
              inputId="CarouselTwoPictureOne"
            />
            <p>
              <strong>Previo</strong>
            </p>
            <ImageZoom imageUrl={pictureOneCarouselTwoPreview} />
          </div>
          <div className="flex flex-col gap-2">
            <p>Foto #2</p>
            <input
              value={pictureTwoCarouselTwoURL}
              type="text"
              className="main-input"
            />
            <ImageUploader
              setSelectedFile={setPictureTwoCarouselTwo}
              inputId="CarouselTwoPictureTwo"
            />
            <p>
              <strong>Previo</strong>
            </p>
            <ImageZoom imageUrl={pictureTwoCarouselTwoPreview} />
          </div>
          <div className="flex flex-col gap-2">
            <p>Foto #3</p>
            <input
              value={pictureThreeCarouselTwoURL}
              type="text"
              className="main-input"
            />
            <ImageUploader
              setSelectedFile={setPictureThreeCarouselTwo}
              inputId="CarouselTwoPictureThree"
            />
            <p>
              <strong>Previo</strong>
            </p>
            <ImageZoom imageUrl={pictureThreeCarouselTwoPreview} />
          </div>
          <div className="flex flex-col gap-2">
            <p>Foto #4</p>
            <input
              value={pictureFourCarouselTwoURL}
              type="text"
              className="main-input"
            />
            <ImageUploader
              setSelectedFile={setPictureFourCarouselTwo}
              inputId="CarouselTwoPictureFour"
            />
            <p>
              <strong>Previo</strong>
            </p>
            <ImageZoom imageUrl={pictureFourCarouselTwoPreview} />
          </div>
          <div className="flex flex-col gap-2">
            <p>Foto #5</p>
            <input
              value={pictureFiveCarouselTwoURL}
              type="text"
              className="main-input"
            />
            <ImageUploader
              setSelectedFile={setPictureFiveCarouselTwo}
              inputId="CarouselTwoPictureFive"
            />
            <p>
              <strong>Previo</strong>
            </p>
            <ImageZoom imageUrl={pictureFiveCarouselTwoPreview} />
          </div>
        </div>
      </form>
    </div>
  );
};

export default CarouselTwo;
