import AWS from "aws-sdk";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import ImageUploader from "../../misc/ImageUploader";
import ImageZoom from "../../misc/ImageWithZoom";
import Carousel from "../../misc/Carousel";
import Save from "../../../../../icons/save";

const CarouselOne = () => {
  const s3 = new AWS.S3({
    accessKeyId: `${process.env.REACT_APP_AWS_KEYID}`,
    secretAccessKey: `${process.env.REACT_APP_AWS_ACCESSKEY}`,
  });
  s3.config.update({ signatureVersion: "v4" });

  const [pictureOneCarousel, setPictureOneCarousel] = useState<File>();
  const [pictureTwoCarousel, setPictureTwoCarousel] = useState<File>();
  const [pictureThreeCarousel, setPictureThreeCarousel] = useState<File>();
  const [pictureFourCarousel, setPictureFourCarousel] = useState<File>();
  const [pictureFiveCarousel, setPictureFiveCarousel] = useState<File>();
  const [pictureOneCarouselPreview, setPictureOneCarouselPreview] =
    useState<string>("");
  const [pictureTwoCarouselPreview, setPictureTwoCarouselPreview] =
    useState<string>("");
  const [pictureThreeCarouselPreview, setPictureThreeCarouselPreview] =
    useState<string>("");
  const [pictureFourCarouselPreview, setPictureFourCarouselPreview] =
    useState<string>("");
  const [pictureFiveCarouselPreview, setPictureFiveCarouselPreview] =
    useState<string>("");
  const [carouselOneImages, setCarouselOneImages] = useState<any>([]);
  const [pictureOneCarouselURL, setPictureOneCarouselURL] =
    useState<string>("");
  const [pictureTwoCarouselURL, setPictureTwoCarouselURL] =
    useState<string>("");
  const [pictureThreeCarouselURL, setPictureThreeCarouselURL] =
    useState<string>("");
  const [pictureFourCarouselURL, setPictureFourCarouselURL] =
    useState<string>("");
  const [pictureFiveCarouselURL, setPictureFiveCarouselURL] =
    useState<string>("");

  const addImageToCarousel = (newImageUrl: any) => {
    setCarouselOneImages((prevImages: any) => [...prevImages, newImageUrl]);
  };

  const getCarouselOne = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/publicidad/carouselOne`
      );

      let tempImageOne = response.data.filter(
        (image: any) => image.placement === "CarouselOnePictureOne"
      );
      let tempImageTwo = response.data.filter(
        (image: any) => image.placement === "CarouselOnePictureTwo"
      );

      let tempImageThree = response.data.filter(
        (image: any) => image.placement === "CarouselOnePictureThree"
      );

      let tempImageFour = response.data.filter(
        (image: any) => image.placement === "CarouselOnePictureFour"
      );

      let tempImageFive = response.data.filter(
        (image: any) => image.placement === "CarouselOnePictureFive"
      );

      if (tempImageOne) {
        let paramsS3 = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `${tempImageOne[0].link}`,
          Expires: 60 * 60,
        };

        s3.getSignedUrl("getObject", paramsS3, (err, url) => {
          if (err) {
            console.error(err);
          } else {
            addImageToCarousel({
              image: url,
              external_url: tempImageOne[0].external_url,
            });
            setPictureOneCarouselPreview(url);
            setPictureOneCarouselURL(tempImageOne[0].external_url);
          }
        });
      }

      if (tempImageTwo) {
        let paramsS3 = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `${tempImageTwo[0].link}`,
          Expires: 60 * 60,
        };
        s3.getSignedUrl("getObject", paramsS3, (err, url) => {
          if (err) {
            console.error(err);
          } else {
            addImageToCarousel({
              image: url,
              external_url: tempImageTwo[0].external_url,
            });
            setPictureTwoCarouselPreview(url);
            setPictureTwoCarouselURL(tempImageTwo[0].external_url);
          }
        });
      }

      if (tempImageThree) {
        let paramsS3 = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `${tempImageThree[0].link}`,
          Expires: 60 * 60,
        };
        s3.getSignedUrl("getObject", paramsS3, (err, url) => {
          if (err) {
            console.error(err);
          } else {
            addImageToCarousel({
              image: url,
              external_url: tempImageThree[0].external_url,
            });
            setPictureThreeCarouselPreview(url);
            setPictureThreeCarouselURL(tempImageThree[0].external_url);
          }
        });
      }

      if (tempImageFour) {
        let paramsS3 = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `${tempImageFour[0].link}`,
          Expires: 60 * 60,
        };
        s3.getSignedUrl("getObject", paramsS3, (err, url) => {
          if (err) {
            console.error(err);
          } else {
            addImageToCarousel({
              image: url,
              external_url: tempImageFour[0].external_url,
            });
            setPictureFourCarouselPreview(url);
            setPictureFourCarouselURL(tempImageFour[0].external_url);
          }
        });
      }

      if (tempImageFive) {
        let paramsS3 = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `${tempImageFive[0].link}`,
          Expires: 60 * 60,
        };
        s3.getSignedUrl("getObject", paramsS3, (err, url) => {
          if (err) {
            console.error(err);
          } else {
            addImageToCarousel({
              image: url,
              external_url: tempImageFive[0].external_url,
            });
            setPictureFiveCarouselPreview(url);
            setPictureFiveCarouselURL(tempImageFive[0].external_url);
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCarouselOne = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    Swal.fire({
      title: "Uploading...",
      text: "Please wait while we upload the images.",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      if (pictureOneCarousel) {
        const uploadParams = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `publicidad/${pictureOneCarousel?.name}`,
          Body: pictureOneCarousel,
        };
        await s3.upload(uploadParams).promise();
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/publicidad?type=CarouselOnePictureOne`,
          {
            image: `publicidad/${pictureOneCarousel?.name}`,
            external_url: pictureOneCarouselURL,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "auth-token": `${sessionStorage.getItem("token")}`,
            },
          }
        );
      }

      if (pictureTwoCarousel) {
        const uploadParams = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `publicidad/${pictureTwoCarousel?.name}`,
          Body: pictureTwoCarousel,
        };
        await s3.upload(uploadParams).promise();
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/publicidad?type=CarouselOnePictureTwo`,
          {
            image: `publicidad/${pictureTwoCarousel?.name}`,
            external_url: pictureTwoCarouselURL,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "auth-token": `${sessionStorage.getItem("token")}`,
            },
          }
        );
      }
      if (pictureThreeCarousel) {
        const uploadParams = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `publicidad/${pictureThreeCarousel?.name}`,
          Body: pictureThreeCarousel,
        };
        await s3.upload(uploadParams).promise();
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/publicidad?type=CarouselOnePictureThree`,
          {
            image: `publicidad/${pictureThreeCarousel?.name}`,
            external_url: pictureThreeCarouselURL,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "auth-token": `${sessionStorage.getItem("token")}`,
            },
          }
        );
      }
      if (pictureFourCarousel) {
        const uploadParams = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `publicidad/${pictureFourCarousel?.name}`,
          Body: pictureFourCarousel,
        };
        await s3.upload(uploadParams).promise();
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/publicidad?type=CarouselOnePictureFour`,
          {
            image: `publicidad/${pictureFourCarousel?.name}`,
            external_url: pictureFourCarouselURL,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "auth-token": `${sessionStorage.getItem("token")}`,
            },
          }
        );
      }
      if (pictureFiveCarousel) {
        const uploadParams = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `publicidad/${pictureFiveCarousel?.name}`,
          Body: pictureFiveCarousel,
        };
        await s3.upload(uploadParams).promise();
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/publicidad?type=CarouselOnePictureFive`,
          {
            image: `publicidad/${pictureFiveCarousel?.name}`,
            external_url: pictureFiveCarouselURL,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "auth-token": `${sessionStorage.getItem("token")}`,
            },
          }
        );
      }
      Swal.fire({
        icon: "success",
        title: "Images uploaded successfully",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "An error occurred during the upload.",
      });
      console.error("Error uploading file:", error);
    }
  };

  useEffect(() => {
    getCarouselOne();
  }, []);

  return (
    <div className="p-2 border border-slate-200 rounded m-2">
      <p>
        <strong>Carrucel #1</strong>
      </p>
      <Carousel images={carouselOneImages} />
      <form action="" className="gap-2 my-4" onSubmit={handleCarouselOne}>
        <div className="flex">
          <h1>Seccion Carrucel #1</h1>
          <button>
            <Save size={26} />
          </button>
        </div>
        <div className="flex gap-2">
          <div className="flex flex-col gap-2">
            <p>Foto #1</p>
            <input
              value={pictureOneCarouselURL}
              type="text"
              className="main-input"
            />
            <ImageUploader
              setSelectedFile={setPictureOneCarousel}
              inputId="CarouselOnePictureOne"
            />
            <p>
              <strong>
                <strong>Previo</strong>
              </strong>
            </p>
            <ImageZoom imageUrl={pictureOneCarouselPreview} />
          </div>
          <div className="flex flex-col gap-2">
            <p>Foto #2</p>
            <input
              value={pictureTwoCarouselURL}
              type="text"
              className="main-input"
            />
            <ImageUploader
              setSelectedFile={setPictureTwoCarousel}
              inputId="CarouselOnePictureTwo"
            />
            <p>
              <strong>Previo</strong>
            </p>
            <ImageZoom imageUrl={pictureTwoCarouselPreview} />
          </div>
          <div className="flex flex-col gap-2">
            <p>Foto #3</p>
            <input
              value={pictureThreeCarouselURL}
              type="text"
              className="main-input"
            />
            <ImageUploader
              setSelectedFile={setPictureThreeCarousel}
              inputId="CarouselOnePictureThree"
            />
            <p>
              <strong>Previo</strong>
            </p>
            <ImageZoom imageUrl={pictureThreeCarouselPreview} />
          </div>
          <div className="flex flex-col gap-2">
            <p>Foto #4</p>
            <input
              value={pictureFourCarouselURL}
              type="text"
              className="main-input"
            />
            <ImageUploader
              setSelectedFile={setPictureFourCarousel}
              inputId="CarouselOnePictureFour"
            />
            <p>
              <strong>Previo</strong>
            </p>
            <ImageZoom imageUrl={pictureFourCarouselPreview} />
          </div>
          <div className="flex flex-col gap-2">
            <p>Foto #5</p>
            <input
              value={pictureFiveCarouselURL}
              type="text"
              className="main-input"
            />
            <ImageUploader
              setSelectedFile={setPictureFiveCarousel}
              inputId="CarouselOnePictureFive"
            />
            <p>
              <strong>Previo</strong>
            </p>
            <ImageZoom imageUrl={pictureFiveCarouselPreview} />
          </div>
        </div>
      </form>
    </div>
  );
};

export default CarouselOne;
