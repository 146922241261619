import axios from "axios";
import { useEffect, useState } from "react";
import Status from "../../../../../icons/status";
import { formatToMMDDYYYY } from "../../../../../utils/dates";
import ViewIcon from "../../../../../icons/view";
import View from "./View";

interface Reto {
  usuario_reto_id: number;
  reto_id: number;
  reto: string;
  producto: string;
  fecha_inicio: string;
  fecha_fin: string;
  puntos: number;
  cantidad_total: number;
  cantidad_ventidad: number;
  estado: string;
}

const MyRetos = () => {
  const [retos, setRetos] = useState<Reto[]>([]);
  const [counter, setCounter] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  const [retoView, setRetoView] = useState(false);
  const [retoViewID, setRetoViewID] = useState(0);

  const filteredRetos = retos.filter((reto) => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    const matchesSearchTerm = reto.reto
      ?.toLowerCase()
      .includes(lowerCaseSearchTerm);

    return matchesSearchTerm;
  });

  const getUserRetos = async () => {
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_API_URL
        }/api/usuario_retos/misretos/${sessionStorage.getItem("user_id")}`,
        {
          headers: {
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
          params: { usuario_id: `${sessionStorage.getItem("user_id")}` },
        }
      );
      setRetos(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserRetos();
  }, []);

  return (
    <div className=" w-full flex flex-col gap-3 overflow-clip">
      <p className="text-xl">
        <strong>Retos</strong>
      </p>
      <input
        type="text"
        placeholder="Buscar"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="main-input"
      />
      <ul className="flex flex-col gap-3 items-center overflow-y-scroll ">
        {retos.length === 0 ? (
          <tr>
            <td className="text-center" colSpan={6}>
              No hay retos disponibles
            </td>
          </tr>
        ) : (
          <div
            className="overflow-auto bg-white pb-8 md:pb-14 w-full"
            style={{ maxHeight: "calc(70vh)" }}
          >
            <table className="w-full grow">
              <thead className="bg-gray-50  text-left">
                <tr className="table-head sticky top-0 bg-gray-50">
                  <th>Nombre</th>
                  <th>Producto</th>
                  <th>Fecha de inicio</th>
                  <th>Fecha final</th>
                  <th className="text-center">Puntos</th>
                  <th className="text-center">Acciones</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {retos.length === 0 ? (
                  <tr>
                    <td className="text-center" colSpan={6}>
                      No hay premios disponibles
                    </td>
                  </tr>
                ) : (
                  filteredRetos.map((reto) => (
                    <tr
                      className="hover:bg-slate-100 table-body"
                      key={reto.usuario_reto_id}
                    >
                      <td>{reto.reto}</td>
                      <td>{reto.producto}</td>
                      <td>{formatToMMDDYYYY(reto.fecha_inicio)}</td>
                      <td>{formatToMMDDYYYY(reto.fecha_fin)}</td>
                      <td className="text-center">{reto.puntos}</td>
                      <td>
                        <div className="flex gap-2 m-auto w-fit">
                          <button
                            onClick={() => {
                              setRetoView(!retoView);
                              setRetoViewID(reto.usuario_reto_id);
                            }}
                          >
                            <ViewIcon />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        )}
      </ul>
      <View
        retoView={retoView}
        setRetoView={setRetoView}
        retoViewID={retoViewID}
        setCounter={setCounter}
      />
    </div>
  );
};

export default MyRetos;
