import axios from "axios";
import React, { useEffect, useState } from "react";
import Close from "../../../../../icons/close";
import Trash from "../../../../../icons/trash";
import Save from "../../../../../icons/save";
import Papa from "papaparse";
import Swal from "sweetalert2";

interface Props {
  farmaciaInventoryView: boolean;
  setFarmaciaInventoryView: React.Dispatch<React.SetStateAction<boolean>>;
  farmaciaID: number;
  counterInventory: number;
  setCounterInventory: React.Dispatch<React.SetStateAction<number>>;
}

interface Product {
  producto_id: number;
  nombre: string;
  cantidad: number;
  articulo: string;
  unidad: string;
}

const ProductoInventoryModal = ({
  farmaciaInventoryView,
  setFarmaciaInventoryView,
  farmaciaID,
  counterInventory,
  setCounterInventory,
}: Props) => {
  const [tempProducto, setTempProducto] = useState<Product>({} as Product);
  const [productList, setProductList] = useState<Product[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
  const [isClosing, setIsClosing] = useState(false);

  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = event.target;
    setTempProducto({ ...tempProducto, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      Swal.fire({
        title: "Procesando...",
        text: "No cierre esta ventana.",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      if (selectedProducts.length === 0) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "No hay productos que agregar",
        });
        return;
      }
      console.log(selectedProducts);
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/producto_farmacia/modify`,
        { products: selectedProducts, farmacia_id: farmaciaID },
        {
          headers: {
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );
      setSelectedProducts([]);
      setCounterInventory(counterInventory + 1);
      Swal.fire({
        icon: "success",
        title: "Inventario modificado",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "No se pudo modificar el inventario",
      });
      console.log(error);
    }
  };

  const newProduct = () => {
    const selectedProduct = productList.find(
      (product) => product.producto_id === tempProducto.producto_id
    );

    if (selectedProduct) {
      setSelectedProducts((prevSelectedProducts: any) => {
        const existingProductIndex = prevSelectedProducts.findIndex(
          (p: any) => p.producto_id === tempProducto.producto_id
        );

        if (existingProductIndex >= 0) {
          const newQuantity =
            Number(prevSelectedProducts[existingProductIndex].cantidad) +
            Number(tempProducto.cantidad);

          if (newQuantity <= 0) {
            return prevSelectedProducts.filter(
              (p: any) => p.producto_id !== tempProducto.producto_id
            );
          }

          const updatedSelectedProducts = [...prevSelectedProducts];
          updatedSelectedProducts[existingProductIndex] = {
            ...updatedSelectedProducts[existingProductIndex],
            cantidad: newQuantity,
          };

          return updatedSelectedProducts;
        } else {
          return [
            ...prevSelectedProducts,
            {
              producto_id: selectedProduct.producto_id,
              nombre: selectedProduct.nombre,
              cantidad: tempProducto.cantidad,
            },
          ];
        }
      });
    } else {
      console.error("Product not found!");
    }
  };

  const getProductos = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/productos/active`,
        {
          headers: {
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );
      setProductList(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleProductoChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const selected_producto = event.target.value;
    const foundProduct = productList.find(
      (producto) => producto.nombre === selected_producto
    );

    if (!foundProduct) {
      return;
    }
    setTempProducto({
      ...tempProducto,
      nombre: foundProduct?.nombre || "",
      producto_id: foundProduct?.producto_id,
    });
  };

  const openFileSelector = () => {
    const fileInput = document.getElementById("csvInput") as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    } else {
      console.error("The file input element was not found.");
    }
  };

  const handleCSVUpload = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          const products = results.data;
          updateSelectedProductsFromCSV(products);
        },
      });
    }
  };

  const updateSelectedProductsFromCSV = (products: any[]) => {
    const productUpdates = products.map((product: any) => ({
      articulo: product.Articulo.trim(),
      cantidad: Number(product.Cantidad.toString().trim()),
    }));

    const updatedProducts = [...selectedProducts];
    productUpdates.forEach((update) => {
      const productIndex = updatedProducts.findIndex(
        (p) => p.articulo === update.articulo
      );

      if (productIndex > -1) {
        updatedProducts[productIndex] = {
          ...updatedProducts[productIndex],
          cantidad: updatedProducts[productIndex].cantidad + update.cantidad,
        };
      } else {
        const fullProductDetails = productList.find(
          (p) => p.articulo === update.articulo
        );

        if (fullProductDetails) {
          updatedProducts.push({
            ...fullProductDetails,
            cantidad: update.cantidad,
          });
        } else {
          console.error("Product details not found for ID:", update.articulo);
        }
      }
    });

    setSelectedProducts(updatedProducts);
  };

  useEffect(() => {
    if (farmaciaInventoryView) {
      setTimeout(() => {
        setIsClosing(false);
      }, 1000);
    }
    getProductos();
  }, [farmaciaInventoryView]);

  useEffect(() => {}, [selectedProducts]);

  return (
    <>
      {(farmaciaInventoryView || isClosing) && (
        <div
          className={`bg-white absolute md:relative h-full max-h-screen overflow-auto min-w-full md:min-w-0 w-screen left-0 ${
            isClosing ? "animate-slide-out" : "animate-slide-in"
          }`}
        >
          <div className={`w-full sticky top-0 bg-white py-6 px-10 z-20 `}>
            <div className="flex items-center justify-center">
              <p className="text-lg">
                <strong>Modificar inventario</strong>
              </p>
              <button className="mx-4" onClick={handleSubmit}>
                <Save size={36} />
              </button>
              <div className="grow" />
              <button
                onClick={() => {
                  if (farmaciaInventoryView) {
                    setIsClosing(true);
                    setTimeout(() => {
                      setFarmaciaInventoryView(false);
                      setIsClosing(false);
                    }, 1000);
                  } else {
                    setFarmaciaInventoryView(true);
                  }
                }}
              >
                <Close />
              </button>
            </div>
            <div className="flex gap-4 flex-col">
              <div>
                <label htmlFor="name">Producto</label>
                <input
                  type="text"
                  id="producto_id"
                  name="producto_id"
                  onChange={handleProductoChange}
                  list="names"
                  className="main-input w-full"
                  required
                />
                <datalist id="names">
                  {productList.map((producto) => (
                    <option
                      key={`${producto.producto_id}`}
                      value={`${producto.nombre}`}
                    />
                  ))}
                </datalist>
              </div>
              <div className="block">
                <label htmlFor="" className="">
                  Cantidad
                </label>
                <input
                  type="number"
                  id="cantidad"
                  name="cantidad"
                  value={tempProducto.cantidad}
                  onChange={handleInputChange}
                  className="main-input"
                  required
                />
              </div>
              <button
                className="cefa-button"
                onClick={newProduct}
                type="button"
              >
                Agregar
              </button>
            </div>
          </div>

          <div
            className="border border-gray-300 rounded-lg shadow-md flex flex-col items-center cursor-pointer mx-6"
            onClick={openFileSelector}
          >
            <p className="p-4">Click para subir reporte</p>
            <input
              id="csvInput"
              type="file"
              accept=".csv"
              className="hidden"
              onChange={(event) => {
                handleCSVUpload(event);
              }}
            />
          </div>

          <div className="flex flex-col gap-4 mx-6">
            <div
              className="overflow-auto bg-white pb-8 md:pb-0"
              style={{ maxHeight: "calc(60vh)" }}
            >
              <table className="w-full">
                <thead className="bg-gray-50 top-0 sticky">
                  <tr className="table-head text-left">
                    <th>ID</th>
                    <th>Nombre</th>
                    <th className="text-center">Cantidad</th>
                    <th className="text-center">Acciones</th>
                  </tr>
                </thead>

                <tbody className="bg-white divide-y divide-[#FAFAFA]">
                  {selectedProducts.length === 0 ? (
                    <tr className="hover:bg-slate-300">
                      <td className="text-center" colSpan={6}>
                        No hay productos seleccionados
                      </td>
                    </tr>
                  ) : (
                    selectedProducts.map((producto) => (
                      <tr
                        className="hover:bg-slate-100 table-body "
                        key={producto.producto_id}
                      >
                        <td>{producto.producto_id}</td>
                        <td>{producto.nombre}</td>
                        <td className="text-center">{producto.cantidad}</td>
                        <td>
                          <div className="m-auto w-fit">
                            <button>
                              <Trash size={15} />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProductoInventoryModal;
