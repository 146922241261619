const VideoPlayer = ({ videoUrl }: any) => {
  return (
    <div className="video-player max-w-2xl m-auto">
      <video src={videoUrl} controls width="100%" className="rounded-2xl">
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoPlayer;
