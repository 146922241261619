import axios from "axios";
import { useEffect, useState } from "react";
import Close from "../../../../../icons/close";
import Cefamonedas from "../../../../../icons/cefamoneda";
import Swal from "sweetalert2";

interface CartItem {
  cart_premio_id: number;
  premio: string;
  cantidad: number;
  usuario_cart_id: number;
  puntos: number;
}

interface Props {
  counter: number;
  setCounter: React.Dispatch<React.SetStateAction<number>>;
  cartView: boolean;
  setCartView: React.Dispatch<React.SetStateAction<boolean>>;
  availablePoints: number;
}

const Cart = ({
  cartView,
  setCartView,
  availablePoints,
  counter,
  setCounter,
}: Props) => {
  const [cartItems, setCartItems] = useState<CartItem[]>([]);
  const [isClosing, setIsClosing] = useState(false);

  const getCartItems = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/carrito/${sessionStorage?.getItem(
          "user_id"
        )}`,
        {
          headers: {
            "auth-token": `${sessionStorage?.getItem("token")}`,
          },
        }
      );
      console.log(response.data);
      setCartItems(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteCartItem = async (premio_id: number) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/carrito/${sessionStorage?.getItem(
          "user_id"
        )}/${premio_id}`,
        {
          headers: {
            "auth-token": `${sessionStorage?.getItem("token")}`,
          },
          params: { premio_id: premio_id },
        }
      );

      setCartItems((prevState) =>
        prevState.filter((item) => item.cart_premio_id !== premio_id)
      );
    } catch (error) {
      console.log(error);
    }
  };

  const createPedido = async () => {
    try {
      if (cartItems.length === 0) {
        return Swal.fire({
          icon: "error",
          title: "No hay premios en el carrito",
        });
      }

      const getTotalCost = cartItems.reduce(
        (acc, item) => acc + item.cantidad * item.puntos,
        0
      );
      if (getTotalCost > availablePoints) {
        return Swal.fire({
          icon: "error",
          title: "No tienes suficientes puntos",
        });
      }

      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/pedidos/new`,
        { usuario_id: sessionStorage?.getItem("user_id") },
        {
          headers: {
            "auth-token": `${sessionStorage?.getItem("token")}`,
          },
        }
      );
      Swal.fire({
        icon: "success",
        title: "Pedido realizado",
      });
      setCartView(!cartView);
      setCounter(counter + 1);
    } catch (error) {
      if (
        axios.isAxiosError(error) &&
        error.response &&
        error.response.status === 404
      ) {
        const unfoundProducts = error.response.data.unavailablePremios;
        showUnfoundProductsAlert(unfoundProducts);
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Ha ocurrido un error al enviar los datos.",
        });
      }
    }
  };

  const showUnfoundProductsAlert = (unfoundProducts: any) => {
    const htmlContent = unfoundProducts
      .map((product: any) => `<tr><td>${product.nombre}</td></tr>`)
      .join("");

    Swal.fire({
      title: "Productos no encontrados",
      icon: "warning",
      html: `
        <p>Los siguientes productos no están disponibles:</p>
        <table class="table">
          <thead>
            <tr><th>Producto</th></tr>
          </thead>
          <tbody>
            ${htmlContent}
          </tbody>
        </table>
      `,
      confirmButtonText: "Aceptar",
    });
  };
  useEffect(() => {
    if (cartView === false) {
      setTimeout(() => {
        setCartItems({} as CartItem[]);
      }, 500);
    } else {
      getCartItems();
    }
  }, [cartView]);

  return (
    <>
      {cartView || isClosing ? (
        <div className={`${cartView ? "bg-drawer" : "hidden"}`}>
          <div className="grow"></div>
          <div
            className={`h-full bg-white p-2 w-screen md:w-1/3 md:min-w-fit min-w-full px-6 ${
              isClosing ? "animate-slide-out" : "animate-slide-in"
            }`}
          >
            <div className="w-full sticky top-0 bg-white">
              <div className="w-full flex items-center my-6">
                <button
                  onClick={() => {
                    if (cartView) {
                      setIsClosing(true);
                      setTimeout(() => {
                        setCartView(false);
                        setIsClosing(false);
                      }, 300);
                    } else {
                      setCartView(true);
                    }
                  }}
                >
                  <Close />
                </button>
                <div className="grow"></div>
                <h1 className="text-2xl">Carrito</h1>
              </div>
            </div>
            <div className="">
              <div className="flex my-4">
                <p className="text-lg">
                  <strong>{availablePoints}</strong>
                </p>
                <Cefamonedas />
              </div>
              <table className="w-full m-auto">
                <thead>
                  <tr className="border-b border-gray-400">
                    <th>Accion</th>
                    <th className="text-left">Producto</th>
                    <th className="text-center">Cantidad</th>
                    <th className="text-center">Costo</th>
                  </tr>
                </thead>
                {cartItems.length === 0 ? (
                  <tr>
                    <td className="text-center" colSpan={6}>
                      No hay premios en el carrito
                    </td>
                  </tr>
                ) : (
                  <tbody>
                    {Array.isArray(cartItems) &&
                      cartItems.map((item) => (
                        <tr key={item.premio}>
                          <td>
                            <div className="m-auto w-fit">
                              <button
                                onClick={() => {
                                  deleteCartItem(item.cart_premio_id);
                                }}
                              >
                                <svg
                                  height="48"
                                  viewBox="0 96 960 960"
                                  width="36"
                                  className="fill-current"
                                >
                                  <path d="M261 936q-24.75 0-42.375-17.625T201 876V306h-41v-60h188v-30h264v30h188v60h-41v570q0 24-18 42t-42 18H261Zm438-630H261v570h438V306ZM367 790h60V391h-60v399Zm166 0h60V391h-60v399ZM261 306v570-570Z" />
                                </svg>
                              </button>
                            </div>
                          </td>
                          <td className="text-left">{item.premio}</td>
                          <td className="text-center">{item.cantidad}</td>
                          <td className="text-center">
                            {item.cantidad * item.puntos}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                )}
              </table>
            </div>
            <button onClick={createPedido} className="cefa-button my-2">
              Realizar pedido
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Cart;
