import axios from "axios";
import { useEffect, useState } from "react";

interface pedidoItem {
  pedido_item_id: number;
  cantidad: number;
  nombre: string;
  premio_id: number;
}
interface Props {
  pedidoViewID: number;
}
const PedidosItemsList = ({ pedidoViewID }: Props) => {
  const [pedidoItems, setPedidoItems] = useState<pedidoItem[]>([]);

  const getPedidoItems = async (pedidoViewID: number) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/pedidos/items/${pedidoViewID}`,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );

      setPedidoItems(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPedidoItems(pedidoViewID);
  }, [pedidoViewID]);

  return (
    <div>
      <p className="my-4 text-center">
        <strong>Articulos Solicitados</strong>
      </p>
      <table className="w-full">
        <thead className="border-b-2 border-black">
          <tr className="text-left flex">
            <th className="grow">Nombre</th>
            <th>Cantidad</th>
          </tr>
        </thead>
        <tbody>
          {pedidoItems.map((pedido) => (
            <tr key={pedido.premio_id} className="flex gap-2">
              <th className="grow text-left">{pedido.nombre}</th>
              <th>{pedido.cantidad}</th>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PedidosItemsList;
