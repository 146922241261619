const view = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.41976 13.2132C2.28357 12.9975 2.21548 12.8897 2.17736 12.7234C2.14873 12.5985 2.14873 12.4015 2.17736 12.2766C2.21548 12.1103 2.28357 12.0025 2.41976 11.7868C3.54516 10.0048 6.89503 5.5 12 5.5C17.105 5.5 20.4549 10.0048 21.5803 11.7868C21.7165 12.0025 21.7846 12.1103 21.8227 12.2766C21.8513 12.4015 21.8513 12.5985 21.8227 12.7234C21.7846 12.8897 21.7165 12.9975 21.5803 13.2132C20.4549 14.9952 17.105 19.5 12 19.5C6.89503 19.5 3.54516 14.9952 2.41976 13.2132Z"
        stroke="#52525B"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 15.5C13.6569 15.5 15 14.1569 15 12.5C15 10.8431 13.6569 9.5 12 9.5C10.3432 9.5 9.00004 10.8431 9.00004 12.5C9.00004 14.1569 10.3432 15.5 12 15.5Z"
        stroke="#52525B"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default view;
