import { Routes, Route } from "react-router-dom";

//Import of Routes
import Landing from "./routes/public/Landing";
import AdminHome from "./routes/private/components/admin/Home";
import { Unknown } from "./routes/public/Unknown";
import UserHome from "./routes/private/components/usuario/Home";
import Invitation from "./routes/private/components/usuario/invitation/invitation";
import Unauthorized from "./routes/public/Unauthorized";
import ResetPassword from "./routes/public/ResetPassword";
import { useEffect } from "react";
import PasswordReset from "./routes/public/PasswordReset";

function App() {
  useEffect(() => {
    document.title = "Cefaplus";
  }, []);
  return (
    <>
      <Routes>
        <Route index element={<Landing />} />
        <Route path="/invitation/:token/:email" element={<Invitation />} />
        <Route path="/landing" element={<Landing />} />
        <Route path="/password-reset" element={<PasswordReset />} />
        <Route
          path="/reset-password/:token/:email"
          element={<ResetPassword />}
        />
        <Route path="/user">
          <Route index element={<UserHome />}></Route>
        </Route>
        <Route path="admin">
          <Route index element={<AdminHome />} />
        </Route>
        <Route path="/unauthorized" element={<Unauthorized />} />
        {/* <Route path="/*" element={<Unknown />} /> */}
      </Routes>
    </>
  );
}

export default App;
