interface Props {
  status?: string;
}
const status = ({ status }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="14">
      <circle
        cx="256"
        cy="256"
        r="208"
        fill={`${status === "Activo" ? "#17B26A" : "#E94747"}`}
      />

      <circle
        cx="256"
        cy="256"
        r="208"
        fill="none"
        stroke="black"
        stroke-width="4"
      />
    </svg>
  );
};

export default status;
