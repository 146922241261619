import axios from "axios";
import { useEffect, useState } from "react";

interface pedidoItem {
  pedido_items_id: number;
  nombre: string;
  cantidad: number;
}
interface Props {
  pedidoViewID: number;
}

const PedidoItems = ({ pedidoViewID }: Props) => {
  const [pedidoItems, setPedidoItems] = useState<pedidoItem[]>();

  const getPedidoItems = async (pedidoViewID: any) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/pedidos/items/${pedidoViewID}`,
      {
        headers: {
          "auth-token": `${sessionStorage.getItem("token")}`,
        },
      }
    );
    setPedidoItems(response.data);
  };

  useEffect(() => {
    getPedidoItems(pedidoViewID);
  }, [pedidoViewID]);

  return (
    <div className="flex flex-col ga-2">
      {pedidoItems?.map((pedidos) => (
        <div
          key={pedidos.pedido_items_id}
          className="border-2 border-black p-2 rounded"
        >
          <p>Articulo: {pedidos.nombre}</p>
          <p>Cantidad: {pedidos.cantidad}</p>
        </div>
      ))}
    </div>
  );
};

export default PedidoItems;
