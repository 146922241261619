export function formatToDate(dateInput: any) {
  if (!dateInput) return "";

  const date = new Date(dateInput);
  if (isNaN(date.getTime())) return "";

  return date.toISOString().substr(0, 10);
}

export function formatToMMDDYYYY(isoDateString: any) {
  const date = new Date(isoDateString);

  const day = ("0" + date.getUTCDate()).slice(-2);
  const month = ("0" + (date.getUTCMonth() + 1)).slice(-2);
  const year = date.getUTCFullYear();

  return `${month}/${day}/${year}`;
}
